import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import accountDocumentSet from './modules/accountDocumentSet'
import { loader } from './modules/loader';
import accountRequest from './modules/accountRequest';
import signedAgreement from './modules/signedAgreement';

Vue.use(Vuex)


//Центральное хранилище данных.
export default new Vuex.Store({
    modules: {
        loader,
        account,
        accountDocumentSet,
        accountRequest,
        signedAgreement
    },
    state: {
        isUatModalShow: true,
        isCheckAgreement: false,
        checkReferrerPhoneCount: 0,
        referrerMobilePhone: null,
        fromRouteToVerification: null,
        isAccountRequestRejected: null
    },
    mutations: {
        // Установить флаг принятия соглашения.
        setIsCheckAgreement(state, isCheckAgreement) {
            state.isCheckAgreement = isCheckAgreement
        },
        // Установить флаг модального окна UAT.
        setisUatModalShow(state, isUatModalShow) {
            state.isUatModalShow = isUatModalShow
        },
        // Установить количество проверок номера рекомендателя.
        setCheckReferrerPhoneCount(state, count) {
            state.checkReferrerPhoneCount = count
        },
        // Установить телефон рекомендателя.
        setReferrerMobilePhone(state, phone) {
            state.referrerMobilePhone = phone
        },
        // Установить флаг редактирования обращения.
        setFromRouteToVerification(state, fromRouteToVerification) {
            state.fromRouteToVerification = fromRouteToVerification
        },
        setIsAccountRequestRejected(state, isAccountRequestRejected) {
            state.isAccountRequestRejected = isAccountRequestRejected
        }
    },
    actions: {
        // Установить флаг редактирования обращения.
        setFromRouteToVerification({commit}, fromRouteToVerification) {
            commit('setFromRouteToVerification', fromRouteToVerification)
        },
        // Установить флаг модального окна UAT.
        setisUatModalShow({commit}, isUatModalShow) {
            commit('setisUatModalShow', isUatModalShow)
        },
        // Установить флаг принятия соглашения.
        setIsCheckAgreement({commit}, isCheckAgreement) {
            commit('setIsCheckAgreement', isCheckAgreement)
        },
        // Установить количество проверок номера рекомендателя.
        setCheckReferrerPhoneCount({commit}, count) {
            commit('setCheckReferrerPhoneCount', count)
        },
        // Установить телефон рекомендателя.
        setReferrerMobilePhone({commit}, phone) {
            commit('setReferrerMobilePhone', phone)
        },
        setIsAccountRequestRejected({commit}, isAccountRequestRejected) {
            commit('setIsAccountRequestRejected', isAccountRequestRejected)
        }
    },
    getters: {
        // Получить флаг принятия соглашения.
        getIsCheckAgreement: state => state.isCheckAgreement,
        getisUatModalShow: state => state.isUatModalShow,
        // Получить количество проверок номера рекомендателя.
        getCheckReferrerPhoneCount: state => state.checkReferrerPhoneCount,
        getReferrerMobilePhone: state => state.referrerMobilePhone,
        getfromRouteToVerification: state => state.fromRouteToVerification,
        getIsAccountRequestRejected: state => state.isAccountRequestRejected
    }

})