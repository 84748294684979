import baseRepository from '../BaseRepository';

const resource = '/accountdocumentset';

import qs from 'qs'

//Сервис настроек обязательности документов.
export default {
    //Получить все настройки документов.
    getAccountDocumentSets(include) {
        let methodName = ''
        return baseRepository.get(
            `${resource}${methodName}`, {
                params: {include: include},
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
        })
    }
}