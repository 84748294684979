<template>
   <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <pageTitle title="Добавление торговых точек участника" />
                    <div class="pos-detail-photo-example">
                        Для ускорения идентификации загрузите по одному фото
                        любого кассового чека, напечатанного в каждой из ваших
                        торговых точек.
                        <br /><br />
                        Принимаются как бумажные, так и электронные чеки, на
                        которых присутствует обязательная информация:
                        <div style="margin-top: 10px; font-weight: bold;">
                            <ul>
                                <li>ИНН</li>
                                <li>Наименование ЮЛ</li>
                                <li>Адрес торговой точки</li>
                            </ul>
                         </div>
                         <router-link class="pos-detail-photo-example-href"
                                      :to="`/example`">
                             Посмотреть пример
                         </router-link>
                    </div>
                    <fieldset :disabled="isAccountRequestRejected">
                        <form @submit.prevent="">
                            <transition-group :duration="{ enter: 700, leave: 200 }" name="fade" tag="div">
                                <div class="accordion-item" v-for="(pos, index) in poses" :key="index">
                                    <div class="accordion-item-head" @click="accordionActive(index)">
                                        <div class="accordion-item-head-title">Точка №{{index+1}}</div>
                                        <font-awesome-icon icon="chevron-down" class="accordion-item-head-arrow"/>
                                        <font-awesome-icon icon="chevron-up" class="accordion-item-head-arrow hide"/>
                                    </div>
                                    <div class="accordion-item-body" :class="{'active': index+1 == 1}" ref="accordionItemBody">
                                        <annotationsUploader :canDeleteImg="!isAccountRequestRejected" class="accordion-item-body-annotation" ref="poses" :annotations.sync="pos.annotations" :isMultiple="false" btnTitle="Фото чека"/>
                                    </div>
                                </div>
                                </transition-group>
                                <div v-if="!isAccountRequestRejected" class="table-button-item">
                                    <div @click="addPos" class="table-button-item-add">
                                        <font-awesome-icon icon="plus" class="icon"/>Добавить
                                    </div>
                                    <div @click="delPos" v-if="showDel" class="table-button-item-del">
                                        <font-awesome-icon icon="minus" class="icon"/>Убрать
                                    </div>
                                </div>
                        </form>
                    </fieldset>
                    <buttonNavigation :backTo="`/profiledetail`" :nextTo="`/documents`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import annotationsUploader from '@/components/AnnotationsUploader.vue'

export default {
    components: {
        buttonNavigation,
        pageTitle,
        annotationsUploader
    },
    data() {
        return {
            image: {},
            poses: this.$store.getters.getAccoutRequest.accountRequestPoses.length > 0
                ? [... this.$store.getters.getAccoutRequest.accountRequestPoses]
                : [
                    {
                        accountRequestPosId: null,
                        annotations: []
                    }
                ],
            isAccountRequestRejected: this.$store.getters.getIsAccountRequestRejected
        }
    },
    computed: {
        showDel() {
            return this.poses.length > 1
        },
    },
    methods: {
        //Кривой, но работающий аккордеон !todo *(Вынести в отдельный компонент для многоразового использования.)
        accordionActive: function (index) {
            this.$refs.poses[index].$forceUpdate()
            let accordionItems = this.$refs.accordionItemBody;
            let upArrows = this.$refs.up;
            let downArrows = this.$refs.down;
            accordionItems.forEach((item, key) => {
                if (key == index) {
                    item.classList.toggle('active')
                    item.parentNode.firstChild.childNodes.forEach((e,index,arr) => {
                        if (index == 0) return
                        e.classList.toggle('hide')
                    })
                }
                else {
                    item.classList.remove('active')
                    item.parentNode.firstChild.childNodes.forEach((e,index,arr) => {
                        if (index == 0) return
                       arr[1].classList.remove('hide')
                       arr[2].classList.add('hide')
                    })
                }
            })
        },
        addPos() {
            this.poses.push({
                id: this.$_.last(this.poses).id + 1,
                annotations: []
            })
        },
        delPos() {
            this.poses.pop()
        },
    },
    watch: {
        poses: {
            handler: function(val, oldVal) {
                let resultPosArray = this.poses.filter(item => item.annotations.length > 0)
                if (resultPosArray.length > 0)
                    this.$store.dispatch('setAccountRequestPoses', resultPosArray)
            },
            deep: true
        }
    },
    beforeRouteLeave (to, from, next) {
        if (to.path == '/example' || to.path == '/profiledetail') {
            next()
            return
        }
        let resultPosArray = this.poses.filter(item => item.annotations.length > 0)
        if (resultPosArray.length > 0) {
            this.$store.dispatch('setAccountRequestPoses', resultPosArray)
            next()
        } else {
            this.$toast.error(
                this.$helper.messages.errorPosNotExist,
                "",
                this.$helper.toast.error
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.pos-detail-photo-example {
    text-align: left;
    font-size: 15px;
    //border: 1px solid #dfdfdf;
    //border-radius: 5px;
    padding: 8px;
    //background-color: blanchedalmond;
    .pos-detail-photo-example-href{
        font-size: 16px;
        color: #0FA1DB;
    }
}
.accordion-item-head-title {
    font-weight: 550;
}
.table-button-item{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.table-button-item-add, .table-button-item-del{
    cursor: pointer;
    margin: 0 10px;
    &:hover {
        color: #0FA1DB;
    }
}

.accordion-item{
    display: flex;
    flex-direction: column;
}

.accordion-item-head{
    margin-top: 10px;
	background-color: #dfdfdf;
	border-top-left-radius: 5px ;
	border-top-right-radius: 5px;
	cursor: pointer;
    padding:10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordion-item-head-arrow{
    margin-left: 10px;
}
.accordion-item-head-arrow.hide {
    display: none;
}

.accordion-item-body{
    padding-bottom: 10px;
    display: none;
    margin-bottom: 10px;
    height: auto;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;;
}
.accordion-item-body-annotation {
    margin-top: 20px;
    margin-bottom: 15px;
}

.accordion-item-body.active  {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s;
}
</style>