//Хелперы.
import { Enums } from '../../helpers/Enums'
import { Helper } from '@/helpers/Helper'

//Модуль хранилища обращений.
export default {
    state: {
        accountRequest: {
            accountRequestId: null,
            account: {},
            requestType: Enums.accountRequest.ACCOUNT_REQUEST_TYPES.SelfRegistration,
            accountRequestTypeId : '00000000-0000-0000-0000-000000000000',
            annotations: [],
            accountRequestPoses: [],
            statusCode: null
        }
    },
    mutations: {
        setAccountRequestPoses(state, payload) {
            state.accountRequest.accountRequestPoses = payload
        },
        setAccoutToAccountRequest(state, payload) {
            state.accountRequest.account = payload
        },
        setAccountRequestAnnotations(state, payload) {
            state.accountRequest.annotations = payload
        },
        setAccountRequest(state, payload) {
            for (let [key, val] of Object.entries(state.accountRequest)) {
                state.accountRequest[key] = payload[key]
            }
        }
    },
    actions: {
        setAccountRequestPoses({commit}, poses) {
            commit('setAccountRequestPoses', poses)
        },
        setAccoutToAccountRequest({commit}, account) {
            commit('setAccoutToAccountRequest', account)
        },
        setAccountRequestAnnotations({commit, getters}, documents) {
            let accountRequestAnnotations = [];
            documents.forEach(d => {
                let annotations = d.annotations.map(a => {
                    a.noteText = d.name
                    accountRequestAnnotations.push(a)
                })
            });
            commit('setAccountRequestAnnotations', accountRequestAnnotations)
        },
        setAccountRequest({commit}, accountRequest) {
            commit('setAccountRequest', accountRequest)
        }
    },
    getters: {
        getAccoutRequest: state => state.accountRequest
    }
}