<template>
   <div class="wrapper">
        <div class="container">
            <div class="col align-items-center" style="text-align: center">
                <div v-if="isSuccess" style="font-size: 20px">
                    <font-awesome-icon icon="check" size="3x" :style="{color: '#00C851', marginBottom: '20px'}"/>
                    <div>Спасибо за регистрацию!</div>
                    <div style="margin-top: 20px">После проверки документов на указанный вами номер придет SMS с инструкцией по первому входу в программу. Обращаем внимание, что процесс проверки документов может занимать до 2 рабочих дней.</div>
                    <div style="margin-top: 20px">Вы всегда можете уточнить статус своей заявки по телефону Центра Поддержки <a href="tel:88006008075">8 800 600 80 75</a>. Наши специалисты готовы ответить на ваши вопросы в рабочие дни с 9.00 до 18.00 (звонок бесплатный).</div>
                    <h3 style="color: #4B515D; margin-top: 50px"><a :href="$helper.sfdcUrlUat">Вернуться на страницу входа</a></h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountRequestService = repositoryFactory.get('accountRequest')
const SignedAgreementServie = repositoryFactory.get('signedAgreement')
    const AccountRequestTypeService = repositoryFactory.get('accountRequestType')

export default {
    data() {
        return {
            isSuccess: false
        }
    },
    created() {
        //Пробуем сохранить обращение.
        this.$recaptchaLoaded().then(() => {
            this.$recaptcha("AccountRequestSave").then(token => {
                let accountRequest = this.$store.getters.getAccoutRequest
                AccountRequestTypeService.getSelfRegistrationRequestType().then(accountRequestTypeIdData => {
                accountRequest.accountRequestTypeId = accountRequestTypeIdData.data;
                AccountRequestService
                    .save(token, accountRequest)
                    .then(res => {
                        if (res && res.data) {
                            let agreement = this.$store.getters.getAgreement
                            if (agreement.agreementId) {
                                //Получить только что созданное обращение *(нужен guid профиля)
                                AccountRequestService
                                    .getAccountRequestById(res.data)
                                    .then(res => {
                                        if (res && res.data) {
                                            if (res.data.accountId) {
                                                let signedAgreement = { ...this.$store.getters.getSignedAgreement}
                                                signedAgreement.accountId = res.data.accountId
                                                signedAgreement.agreementId = agreement.agreementId
                                                this.$store.dispatch('setSignedAgreementField', signedAgreement)
                                                //Пробуем сохранить подписанное соглашение.
                                                SignedAgreementServie
                                                    .saveSignedAgreement(signedAgreement)
                                                    .then(res => { this.isSuccess = true } )
                                                    .catch(error =>
                                                        this.$toast.error(
                                                            `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                                                            "",
                                                            this.$helper.toast.error
                                                    ))
                                            } else {
                                                this.isSuccess = true
                                            }
                                        }
                                    })
                                    .catch(error =>
                                        this.$toast.error(
                                            `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                                            "",
                                            this.$helper.toast.error
                                    ))
                            }
                        }
                    })
                    .catch(error =>
                        this.$toast.error(
                            `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                            "",
                            this.$helper.toast.error
                    ))
                })
            })
        })
    }
}
</script>

<style lang="scss">

</style>