<template>
    <div class="page-title">{{ title }}</div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>
.<style lang="scss" scoped>
.page-title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: #333333;
    font-size: 20x;
    font-weight: 600;
    text-align: center;
}
</style>