<template>
    <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9" v-show="afterLoadShowContent">
                    <pageTitle title="Введите код из СМС" />
                    <codeInput :fields="4" v-if="!isTimeleft" @complete="onCompleteCode" />
                    <div class="sms-information">
                        <div v-if="!isTimeleft">Запросить код повторно можно через <span>{{ timeCurrentCount }}</span> сек</div>
                        <a href="#" @click="reSendCode" v-if="isTimeleft || tryCount > maxTryCountLocal">Отправить код повторно</a>
                    </div>
                    <buttonNavigation v-if="isTimeleft" :backTo="backTo" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const VerificationCodeService = repositoryFactory.get('verificationCode')


//Компоненты и зависимости.
import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import codeInput from '@/components/CodeInput.vue'


export default {
    components: {
        buttonNavigation,
        pageTitle,
        codeInput
    },
    data() {
        return {
            account: { ... this.$store.getters.getAccount },
            timer: null,
            timeCurrentCount: 0,
            timeMaxCount: 0,
            isTimeleft: false,
            tryCount: 0,
            maxTryCountLocal: 3,
            afterLoadShowContent: false,
        }
    },
    props: {
        backTo: String,
        nextTo: String
    },
    methods: {
        //Установить таймер повторной отправки.
        setTimer() {
            this.tryCount++
            let self = this
            this.timer = setInterval(() => {
                self.timeCurrentCount--;
                if (self.timeCurrentCount <= 0) {
                    self.stopTimer()
                    self.isTimeleft = !self.isTimeleft
                }
            }, 1000, this.timeMaxCount);      
        },
        //Остановить таймер повторной отправки.
        stopTimer() {
            clearTimeout(this.timer)
        },
        //Повторно отправить смс.
        reSendCode() {
            this.tryCount = 0
            this.stopTimer()
            this.sendCode(this.setShowAll)
        },
        //Выполнить при завершении ввода кода, запустить проверку смс кода.
        onCompleteCode(val) {
             this.tryCount++;
             this.$recaptchaLoaded().then(() => {
                 this.$recaptcha("checkCode").then(token => {
                     VerificationCodeService
                        .checkCode(token, val, this.account.contactMobilePhone)
                            .then(res => {
                                if (res.data && res.data.isSuccess) {
                                    this.$router.push({ path: this.nextTo })
                                } else {
                                    this.$toast.error(
                                        `Неверный код, попробуйте еще раз`,
                                        "",
                                        this.$helper.toast.error
                                    )
                                }
                            })
                 })
             })
        
        },
        setShowAll() {
            this.afterLoadShowContent = true
        },
        //Отправить код.
        sendCode(changeTimeleft) {
            this.$recaptchaLoaded().then(() => {
                this.$recaptcha("sendCode").then(token => {
                    VerificationCodeService
                            .sendVerificationCode(token, this.account.contactMobilePhone)
                                .then(res => {
                                    changeTimeleft()
                                    if (res.data && res.data.isSuccess) {
                                        this.isTimeleft = false
                                        this.timeMaxCount = res.data.timeMaxCount
                                        this.timeCurrentCount = this.timeMaxCount
                                        this.setTimer()
                                    } else {
                                        if (res.data && res.data.isSmsGatewayError) {
                                                this.$toast.error(
                                                `Произошла ошибка на стороне мобильного оператора. Запросите отправку кода повторно.`,
                                                "",
                                                this.$helper.toast.error
                                            )
                                            this.isTimeleft = true;
                                            return
                                        }                                        
                                        if (res.data && res.data.exceededCount) {
                                            this.$toast.error(
                                                `Превышен лимит отправки кода на телефон ${this.account.contactMobilePhone}, попробуйте на следующий день`,
                                                "",
                                                this.$helper.toast.error
                                            )
                                            this.isTimeleft = true;
                                            return
                                        }

                                        throw new this.$helper.UserException("Ошибка отправки кода.")
                                    }
                                }).catch(error => {
                                    console.log(error)
                                    this.$toast.error(
                                        this.$helper.messages.standartErrorMessage,
                                        "",
                                        this.$helper.toast.error
                                    )
                                })
                })
            })
        }
    },
    created() {
        this.sendCode(this.setShowAll)
    },
    beforeRouteLeave (to, from, next) {
        if (to.name == 'profileMain') {
            this.account.contactMobilePhone = ''
            this.$store.dispatch('setAccount', this.account)
            next()
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
.sms-information {
    text-align: center;
    margin-top: 10px;
    a {
        color: #0FA1DB
    };
}
</style>