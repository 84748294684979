import annotationService from './services/annotation.service'
import accountService from './services/account.service'
import accountTypeService from './services/account-type.service'
import accountDocumentSetService from './services/account-document-set.service'
import accountRequestService from './services/account-request.service'
import agreementService from './services/agreement.sevice'
import signedAgreementService from './services/signed-agreement.service'
import verificationCodeService from './services/verification-code.service'
import accountRequestTypeService from './services/account-request-type.service'

//Сервисы для работы с API
const services = {
    annotation: annotationService,
    account: accountService,
    accountType: accountTypeService,
    accountDocumentSet: accountDocumentSetService,
    accountRequest: accountRequestService,
    agreement: agreementService,
    signedAgreement: signedAgreementService,
    verificationCode: verificationCodeService,
    accountRequestType: accountRequestTypeService
}

//Фабрика сервисов
export const repositoryFactory = {
    get: name => services[name]
}