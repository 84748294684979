export default {
	install: (app, options) => {
		var script = document.createElement('script');
		script.src = 'https://chat-ncc.grancall.ru/fv2/chat.js';
		script.async = true;
		document.body.appendChild(script);

		// initialize ncc-chat
		window.addEventListener('chatLoaded', function () {
			window.sdk = initializeChat({
				showcaseId: 1009,
				rootId: 'naumenChatHolder',
				basePath: 'https://chat-ncc.grancall.ru/v2',
				hideOpenButton: false,
				attributes: {}
			});
			window.sdk.uiStateStore.setChatPos({ bottom: 0, right: 60 });
			onChatLoadAction();
		});
	}
}

function onChatLoadAction(iterationCount = 0) {
	var isLoad = false;
	var chatContainer = document.getElementById('naumenChatHolder');
	// Получаем контейнер чата, если сможем получить то чат загрузился
	if (!!chatContainer) {
		chatContainer = chatContainer.firstChild;
		if (!!chatContainer) {
			chatContainer = chatContainer.firstChild;
			if (!!chatContainer) {
				isLoad = true;
				chatContainer.setAttribute('style', 'bottom: 80px !important;right: 0 !important;');
				if (!window.sdk.uiStateStore.chatPos) {
					window.sdk.uiStateStore.setChatPos({ bottom: 0, right: 60 });
				}
			}
		}
	}

	if (!isLoad && iterationCount < 20) {
		setTimeout(onChatLoadAction, 100, iterationCount + 1);
	}
}
