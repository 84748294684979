<template>
    <div class="code-input-block">
        <div v-for="field in fields" :key="field">
            <input
                type="tel" 
                pattern= "[0-9]" 
                class="form-control input-item" 
                maxlength="1"
                ref="items"
                @input="handleInput(field-1)" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fields: {
            type: Number,
            required: false,
            default: 5
        },
        complete: Function
    },
    data() {
        return {
            codes: [],
            currentIndex: 0,
        }
    },
    methods: {
        handleInput(index) {
            let val = this.$refs.items[index].value
            if (!val) return
            
            if (this.codes.length >= index)
                this.codes[index] = val
            else 
                this.codes.push(val)
            this.currentIndex = index

            if (index == this.fields - 1) {
                this.$refs.items[index].blur()
                this.onComplete(this.codes.join(''))
                this.clearRefInputsValue()
                
            } else {
                this.$refs.items[index + 1].focus()
            }
        },
        clearRefInputsValue() {
            for(let i = 0; i < this.$refs.items.length; i++) {
                this.$refs.items[i].value = null
            }
        },
        onComplete(val) {
            this.$emit("complete", val);
        }
    }
}
</script>

<style lang="scss" scoped>
.code-input-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.input-item {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 5px;
    text-align: center; 
}
</style>