import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

//Компоненты-страницы.
import ProfileMain from '@/pages/ProfileMain'
import ProfileDetail from '@/pages/ProfileDetail'
import PosDetail from '@/pages/PosDetail'
import Thanks from '@/pages/Thanks'
import Documents from '@/pages/Documents'
import ReceiptExample from '@/pages/ReceiptExample'
import NotFound from '@/pages/404'
import Referrer from '@/pages/Referrer'
import Verification from '@/pages/Verification'
import Main from '@/pages/Main'
import ExistsPhone from '@/pages/ExistsPhone'
import EditInfo from '@/pages/editInfo'

//Проверочка чтобы не вбивали роуты руками. Т.к. приложение с последовательным вводом данных.
const beforeEnterChecker = (to, from, next) => { if(!from.name) next('/'); else next() }

//Прокидываем роуты для страницы проверки кода из смс.
let backTo = '', nextTo = '' 
const fromRouteToVerification = (to, from, next) => { if (from.name == 'existsPhone') { backTo = '/existsPhone'; nextTo = '/editInfo' } else { backTo = '/profileMain'; nextTo = '/profiledetail'}}

//Роуты приложения.
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'main',
            component: Main
        },
        {
            path: '/editInfo',
            name: 'editInfo',
            component: EditInfo,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/profileMain',
            name: 'profileMain',
            component: ProfileMain,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/existsPhone',
            name: 'existsPhone',
            component: ExistsPhone,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/verification',
            name: 'verification',
            component: Verification,
            props: route => ({ backTo, nextTo }),
            beforeEnter: (to, from, next) => { fromRouteToVerification(to, from, next); beforeEnterChecker(to, from, next) } 
        },
        {
            path: '/profiledetail',
            name: 'profileDetail',
            component: ProfileDetail,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/posdetail',
            name: 'posDetail',
            component: PosDetail,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/referrer',
            name: 'referrer',
            component: Referrer,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/thanks',
            name: 'thanks',
            component: Thanks,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/documents',
            name: 'documents',
            component: Documents,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/example',
            name: 'example',
            component: ReceiptExample,
            beforeEnter: beforeEnterChecker
        },
        {
            path: '/*',
            name: 'notFound',
            component: NotFound
        },
    ]
})