//Хранилище для работы с отображением этапа загрузи. *(см. промежуточное ПО в helpers)
export const loader = {
    namespaced: true,
    state: {
        loading: false,
        reqCount : 0,
    },
    mutations: {
        showSpinner(state) {
            state.loading = true;
        },
        hideSpinner(state) {
            state.loading = false;
        },
        plusReqCount(state) {
            state.reqCount+=1
            this.dispatch("loader/showSpinner")
        },
        minusReqCount(state) {
            state.reqCount-=1
            if (state.reqCount <= 0) this.dispatch("loader/hideSpinner")
        }
    },
    actions: {
        showSpinner({ commit }) {
            commit("showSpinner");
        },
        hideSpinner({ commit }) {
            commit("hideSpinner");
        },
        plusReqCount({commit}) {
            commit('plusReqCount')
        },
        minusReqCount({commit}) {
            commit('minusReqCount')
        }
    },
    getters: {
        getCounter: state => state.reqCount,
        getLoading: state => state.loading
    }
};