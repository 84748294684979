import baseRepository from '../BaseRepository';

const resource = '/account';

import qs from 'qs'

//Сервис профиля.
export default {
    //Получить профиль по номеру моб. телефона.
    getAccountByPhone(token, phone, statusCodes = [], phoneReferral = null) {
        let methodName = '/GetAccountByPhone'
        return baseRepository.get(
                `${resource}${methodName}`,
                {
                    params: {
                        phone: phone,
                        statusCodes: statusCodes,
                        phoneReferral: phoneReferral
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    },
                    headers: { "re-captcha-token": token }
                }
            );
    }
}