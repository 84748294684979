import baseRepository from '../BaseRepository';

const resource = '/signedAgreement';

//Сервис подписанных соглашений.
export default {
    //Сохнанить подписанное соглашение.
    saveSignedAgreement(signedAgreement) {
        let methodName = '/save'
        return baseRepository.post(`${resource}${methodName}`, signedAgreement)
    }
}