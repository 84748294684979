import baseRepository from '../BaseRepository';

const resource = '/verificationcode';

import qs from 'qs'

export default {
       //Отправить Смс код.
       sendVerificationCode(token, phone) {
        let methodName = '/SendSmsCodeByPhone'
        return baseRepository.get(
            `${resource}${methodName}`,
            {
                params: {
                    phone: phone
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                },
                headers: { "re-captcha-token": token }
            }
        )
    },
    //Проверить код.
    checkCode(token, code, phone) {
        let methodName = '/CheckCode'
        return baseRepository.get(
            `${resource}${methodName}`,
            {
                params: {
                    code: code,
                    phone: phone
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                },
                headers: { "re-captcha-token": token }
            }
        )
    }
}