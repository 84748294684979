//Енамы. !todo *(Если будет увеличиваться, можно разнести по файлам).
export const Enums = {
    signedAgreement: {
        IS_SIGNED: {
            Yes: true,
            No: false
        }
    },
    accountDocumentSet: {
        DOC_SEC_CRITERIA: {
            Required: 2,
            Optional: 4,
            Undefined: 1
        },
        IS_ORIGINAL_REQUIRED: {
            Yes: true,
            No: false
        },
        IS_ELECTRONIC_AGREEMENT: {
            Yes: 100000000,
            No: 100000001
        }
    },
    accountRequest: {
        ACCOUNT_REQUEST_TYPES: {
            SelfRegistration: 100000000,
            Other: 100000002,
            ChangingData: 100000001
        },
        STATUS_CODES: {
            //Новый.
            New: 1,
            //Обработан.
            Processed: 100000000,
            //Отклонен.
            Rejected: 100000001,
            //В работе.
            InProcess: 100000002

        }
    },
    account: {
        REFERRER_CHANNELS: {
            Pmsm: 100000000,
            Friend: 100000001,
            MarketingCampaign: 100000002

        },
        GENDERS: {
            Male: 100000000,
            Female: 100000001
        },
        ACCOUNT_TYPE: {
            LegalEntity: 1,
            IndividualEntrepreneur: 2,
            PrivateIndividual: 3
        },
        IS_RU_RESIDENT: {
            Yes: 100000000,
            No: 100000001
        },
        LEGAL_ENTITY_TYPE: {
            Legal: 1,
            IndividualEntrepreneur: 2
        },
        ONLY_FREE_TASKS: {
            Yes: 100000000,
            No: 100000001
        },
        IS_OWNER: {
            Yes: 100000000,
            No: 100000001
        },
        HAS_REGISTRATION: {
            Yes: 100000000,
            No: 100000001
        },
        IS_LEGAL_ENTITY_CONTRACTED: {
            Yes: 100000000,
            No: 100000001
        },
        ACCOUNT_SOURCE: {
            Profiling: 100000000,
            SelfRegistration: 100000001
        },
        STATUS_CODES: {
            /// Проверен
            Valid: 1,

            /// Черновик
            Draft: 100000000,

            /// Деактивирован
            Inactive: 2,

            /// Новый
            New: 100000001,

            /// Обновлен
            Updated: 100000002,

            /// Отклонен
            Cancelled: 100000003,

            /// Не указан
            Undefined: -1
        },
        ELECTRONIC_AGREEMENT_SIGNING_CODES: {
            /// Соглашение не определено.
            Undefined: 100000000,

            /// Ожидает подписания.
            WaitingForSigning: 100000001,

            /// Подписано.
            Signed: 100000002,
        },
        COOPERATIONTYPE: {
            Retail: 100000000,
            QPartner: 100000001
        },
    },
    annotations: {
        POLICY: {
            PersonalDataProcessing: 0
        }
    },
    includes: {
		// Участник.
		Account: 'Account',
		// Статус аккаунта.
		AccountStatus: 'AccountStatus',
		// Юрлицо.
		LegalEntity: 'LegalEntity',
		// Статус юрлица.
		LegalEntityStatus: 'LegalEntityStatus',
		// Контакт аккаунта.
		Contact: 'Contact',
		// Статус контакта.
		ContactStatus: 'ContactStatus',
		// Тип аккаунта.
		AccountType: 'AccountType',
		// Тип документа.
		DocumentType: 'DocumentType',
		// Отношение N:N Тип документа - Категория документа.
		DocumentTypeDocumentCategory: 'DocumentTypeDocumentCategory',
		// Документ.
		Document: 'Document',
		// Статус документа.
		DocumentStatus: 'DocumentStatus',
		// Точка продажи в контексте участника.
		PosAccount: 'PosAccount',
		// Точка продажи.
		Pos: 'Pos',
		// Вложение без файла.
		Annotation: 'Annotation',
		// Файл вложения.
		AnnotationFile: 'AnnotationFile',
		// Геолокация.
		Geolocation: 'Geolocation',
		// Родительская точка продажи.
		ParentPos: 'ParentPos',
		// Назначенные программы.
		AssignedProgram: "AssignedProgram",
		// Программа.
		Program: "Program",
		// Тип программы.
		ProgramType: "ProgramType",
		// Канал.
		TradeLayer: "TradeLayer",
		// Статус точки продажи в контексте участника.
		PosAccountStatus: "PosAccountStatus",
		// Заметки.
		Notes: "Notes",
    }
}