//Репозиторий.
import BaseRepository from "../repository/BaseRepository"

const actionScope = `loader`;

//Чекер-перехватчик всех запросов к API. Атоматически показывает спиннер на любую загрузку.
//Работает на увеличение count'a. Можно слать запросы одновременно, крутиться будет пока хоть один активен.
//Можно увеличивать count руками, например, для ожидания сжатия изображения. *(См. хранилище спиннера - loader.js)
export function MiddlewareInterceptors({ dispatch }) {
    BaseRepository
        .interceptors
        .request
        .use(config => {
            dispatch(`loader/plusReqCount`)
            return config
        },
        error => {
            req.done()
            return Promise.reject(error)
    })
    BaseRepository
        .interceptors
        .response
        .use((response) => {
            dispatch(`loader/minusReqCount`)
            return Promise.resolve(response)
        },
        error => {
            dispatch(`loader/minusReqCount`)
            return Promise.reject(error)
        })
}