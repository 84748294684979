<template>
<div class="wrapper-app">
  <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
    <div class="container d-flex justify-content-center">
        <div class="row justify-content-center">
          <div class="navbar-item d-flex flex-row align-items-center">
            <font-awesome-icon icon="address-card" class="logo-icon" size="2x" :style="{ color: 'white', marginRight: '15px'}"/>
            <div class="title">Регистрация участника</div>
          </div>
        </div>
    </div>
    <div v-if="$helper.isUatNode" class="sss d-flex justify-content-center">Вы находитесь на тестовой среде!</div>
  </nav>
  <spinner v-show="getLoading" :size="60"/>
    <router-view v-show="!getLoading" :style="{ marginTop: $helper.isUatNode ? '80px' : '' }"></router-view>
    <div id="naumenChatHolder"></div>
</div>
</template>

<script>


import spinner from '@/components/Spinner.vue'
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    spinner
  },
  data() {
    return {
      transitionName: ''
    }
  },
  computed: {
    ...mapGetters("loader", ["getLoading"])
  }
}
</script>

<style lang="scss">

.wrapper-app {
  margin-top: 70px;
  margin-bottom: 10px;
}
.navbar {
  height: 50px;
  background-color: #65AECB;
}
.navbar-fixed-top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar-item {
  .logo {
    margin-right: 15px;
    height: 50px;
  }
  .title {
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    font-size: 18px;
    color: white;
  }
}
.sss {

  background-color: rgb(245, 71, 71);
  color: white;
  position: fixed !important;
  top: 50px;
  right: 0;
  left: 0;
  z-index: 1030;
}
</style>