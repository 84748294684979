//Простой хелпер-свалка. !todo *(Если будет увеличиваться, можно разнести по файлам).
export const Helper = {
    //Опции для нотификатора.
    toast: {
        error: {
            position: "topCenter",
            messageSize: '17',
            //backgroundColor: 'rgb(255, 138, 128)',
            closeOnClick: true
        }
    },
    //Дефолтные сообщения об ошибках.
    messages: {
        errorAccountExistMessage: "Такой пользователь в системе уже есть, войдите в систему или обратитесь в центр поддержки участников",
        standartErrorMessage: "Ошибка в работе сервиса создания участника.",
        fieldsAreRequared: "Не все поля заполенны корректно",
        errorPosNotExist: "Необходимо добавить минимум одну точку с фотографией чека.",
        needAllDocumentsAttachment: "Необходимо приложить фото ко всем документам!",
        needAgreement: "Необходимо принять условия соглашения",
        errorAccountReferrerExist: "Участника с таким номером телефона нет на сайте. Пожалуйста, проверьте правильность указанного номера или оставьте поле пустым.",
        errorCountLimit: "Вы исчерпали все попытки, нажмите кнопку Отправить.",
        accountRequestNotFound: "Форма регистрации с указанным номером телефона не найдена, заполните новое обращение",
        accountRequestEditNotInStatus: "Вы запросили изменение регистрационной формы. Сейчас это невозможно, т.к. обращение в работе у оператора - позвоните в Центр Поддержки",
        accountRequestEditAccountHasConfirmStatus: "Вы запросили изменение регистрационной формы, но Вы уже являетесь зарегистрированным пользователем. В случае вопросов свяжитесь по телефону с Центром Поддержки",
        accountRequestIsNotReject: "Сейчас внести изменения нельзя, т.к. обращение находится в работе у оператора и доступно только для просмотра",
        bodyTemplateUatHtml: "Вы находитесь на тестовой среде! <p>Для перехода на основную страницу регистрации перейдите по ссылке <a href='https://selfreg.pmixrm.com/'>https://selfreg.pmixrm.com</a>.</p> <p>Для возврата на страницу К!Успеху, используйте адрес <a href='https://ru.pmiopen.com/'>https://ru.pmiopen.com/</a></p>",
        fileDownloadErrorMessage: "Ошибка при загрузке файла, файл не был найден или поврежден."
    },
    sfdcUrl: 'https://ru.pmiopen.com/',
    sfdcUrlUat: 'https://qa-mypmi.cs80.force.com/dteru/',
    api_adress: process.env.VUE_APP_API_ADRESS,
    isUatNode: process.env.VUE_APP_IS_UAT_NODE === 'false' ? false : true,
    //Максимальный размер файла pdf в mb.
    maxPdfFileSize: 2,

    UserException: function(message) {
        this.message = message
        this.name = "Пользовательское исключение"
    },
    CheckReferrerPhoneCountValue: 3,
    //Кастомные методы для валидатора.
    customValidation: {
        minAge: (minAge) => (value) => {
            var date = new Date(value);
            if(!value) return true
            var today = new Date()
            var birthDate = date
            var age = today.getFullYear() - birthDate.getFullYear()
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
            {
                age--;
            }
            return age >= minAge;
        },
        maxAge: (value) => {
            var date = new Date(value);
            return date.getFullYear() > 1900
        },
        phone: (value) => {
            if(!value) return true
            return value.match(/^\d{10}$/) != null
        }
    },
    annotation: {
        decodeBase64: (base64) => {
            var byteCharacters = atob(base64);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            return new Uint8Array(byteNumbers);
        }
    }
}