import baseRepository from '../BaseRepository';

const resource = '/agreement';

import qs from 'qs'

//Сервис соглашений.
export default {
    //Получить соглашение по заданным параметрам.
    getAgreementForSr(freeTaskOnly, typeOfParticipant, isLegalEntityContracted, cooperationType) {
        let methodName = '/GetAgreementForSr'
        return baseRepository.get(
            `${resource}${methodName}`, {
                params: {
                    freeTaskOnly: freeTaskOnly,
                    typeOfParticipant: typeOfParticipant,
                    isLegalEntityContracted: isLegalEntityContracted,
                    cooperationType: cooperationType
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
        })
    }
}