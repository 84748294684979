import baseRepository from '../BaseRepository';

const resource = '/accountrequesttype';

//Сервис типов обращений.
export default {
    //Получить типы аккунтов.
    getSelfRegistrationRequestType() {
        let methodName = '/GetSelfRegistrationTypeId'
        return baseRepository.get(`${resource}${methodName}`);
    }
}