import baseRepository from '../BaseRepository';

const resource = '/accounttype';

//Сервис типов аккунта.
export default {
    //Получить типы аккунтов.
    getAccounTypes() {
        let methodName = ''
        return baseRepository.get(`${resource}${methodName}`);
    }
}