import baseRepository from '../BaseRepository';

const resource = '/accountRequest';

import qs from 'qs'

//Репозиторий обращений.
export default {
    //Созранить обращение.
    save(token, accountRequest) {
        let methodName = '/save'
        return baseRepository.post(`${resource}${methodName}`, accountRequest, { headers: { "re-captcha-token": token } });
    },
    //Получить обращение по Id.
    getAccountRequestById(id) {
        let methodName = '/getAccountRequestById'
        return baseRepository.get(`${resource}${methodName}`, { params: { id: id } });
    },
    getAccountRequestIdByAccountPhone(token, phone) {
        let methodName = '/getAccountRequestIdByAccountPhone'
        return baseRepository.get(
            `${resource}${methodName}`, 
            {
                params: {
                    phone: phone
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                },
                headers: { "re-captcha-token": token }
            },
        );
    },
    getAccountRequestByAccountPhone(token, phone) {
        let methodName = '/getAccountRequestByAccountPhone'
        return baseRepository.get(
            `${resource}${methodName}`, 
            {
                params: {
                    phone: phone
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                },
                headers: { "re-captcha-token": token }
            },
        );
    }
}