<template>
  <transition name="modal">
    <div class="modal__wrapper">
      <div class="modal-content" @click.stop="">
        <div class="modal-header">
          <span class="modal-title"> {{ title }} </span>
        </div>
        <div class="modal-body">
            <div class="modal-body-text" v-html="bodyTemplate"></div>
            <button class="btn btn-primary familiarized-button" @click="$emit('closeModal')">{{ forExitText }}</button>
        </div>
      </div>
      </div>
  </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        bodyTemplate: {
            type: String,
            required: true
        },
        forExitText: {
            type: String,
            required: true
        }
    },
    data () {
        return {
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss" scoped>

.modal-enter {
  opacity: 0
}
.modal-leave-active {
  opacity: 0
}
.modal-enter .modal-content,
.modal-leave-active .modal-content {
  transform: scale(1.2)
}

.modal__wrapper{
  display: flex;
  justify-content: center;
  align-items: top;
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  transition: opacity .2s ease;
  right: 0;
  z-index: 998;
  background-color: rgba(00,00,00,.48);
}

.modal-content {
  position: relative;
  max-width: 300px;
  max-height: 420px;
  font-size: 16px;
  //padding: 20px 18px;
  margin-top: 55px;
  margin-bottom: 40px;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  transition: all .2s ease;
  border-radius: 8px;
  z-index: 999;
  @media screen and (max-width: 900px) {
    width: auto;
    height: auto;
  }

}
.modal-header {
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding-bottom: 20px;
  span {
    font-size: 24px;
  }
  .button-close {
    cursor: pointer;
  }
}
.modal-body {
  text-align: center;
  overflow: auto;
}
.modal-body-text {
  overflow: auto;
}
.familiarized-button {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #0FA1DB;
    border-color: #0FA1DB;
    color: white;
    &:hover {
        background-color: #118bbb;
        color: white;
    }
}

</style>
