<template>
<div class="wrapper-annotation">
    <div class="annotation-loader">
        <div v-show="!annotationsLocal || annotationsLocal.length == 0" class="hexagon"><font-awesome-icon icon="file-image" class="annotation-loader-no-file-icon"/></div>
        <carousel v-images-loaded="imageProgress"  :navigateTo="annotationsLocal.length-1" ref="carousel" v-if="annotationsLocal && annotationsLocal.length > 0" :scrollPerPage="false" :perPage="1" :paginationEnabled="true" :paginationPadding="3" paginationColor="#b3b3b3" paginationActiveColor="#494ce8">
            <slide v-for="(annotation, index) in annotationsLocal" :key="index">
                <div class="annotation-loader-item-box">
                    <div v-if="canDeleteImg" @click="delImage(index)" class="annotation-loader-item-delete"><font-awesome-icon icon="trash-alt" class="annotation-loader-item-delete-icon"/></div>
                    <img :src="getImageUrl(annotation)">
                </div>
            </slide>
        </carousel>
        <button v-bind="annotationsLocal.length > 0 && !isMultiple ? { disabled: 'disabled'} : null" class="btn annotation-loader-button" @click="$refs.fileInput.click()"><font-awesome-icon icon="folder-open" class="icon"/>{{ btnTitle }}</button>
        <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="image/*,application/pdf"
            v-bind="isMultiple ? {multiple: 'multiple'} : null"
            @change="onFilePicked"/>
    </div>
</div>
</template>

<script>

import imageCompression from 'browser-image-compression'
import { repositoryFactory } from '@/repository/RepositoryFactory.js'
import imagesLoaded from 'vue-images-loaded'

//services
const AnnotationService = repositoryFactory.get('annotation')


export default {
    data() {
        return {
            images: [],
            annotationsLocal: [... this.annotations],
            compressOptions: { maxWidthOrHeight: 1024, maxSizeMB: 1, useWebWorker: true}
        }
    },
    props: {
        annotations: {
            type: Array,
            required: true,
            default: () => []
        },
        btnTitle: {
            type: String,
            required: false,
            default: "Добавить"
        },
        isMultiple: {
            type: Boolean,
            required: true
        },
        canDeleteImg: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    directives: {
        imagesLoaded,
        perPageCustom: []
    },
    methods: {
        imageProgress() {
                this.$refs.carousel.$forceUpdate()
         },
        delImage(index) {
            this.images.splice(index, 1)
            this.annotationsLocal.splice(index, 1)
            this.$emit('update:annotations', this.annotationsLocal)
            this.$refs.fileInput.value = '';
        },
        onFilePicked (event) {
            if (!this.isMultiple && this.images.length > 0) return
            let files = event.target.files
            let tmpArray = [];
            files.forEach(f => {
                if (!f.name || !f.size || !f.type) {
                    this.$toast.error(
                            `Ошибка загрузки изображения ${f.name || "unknown file"}`,
                            "",
                            this.$helper.toast.error
                    )
                    return
                }
                if (f.type.indexOf('image/') === -1 && f.type.indexOf('application/pdf') === -1) {
                    this.$toast.error(
                            `Неверный формат файла ${f.name}`,
                            "",
                            this.$helper.toast.error
                    )
                    return
                }
                let annotation = {
                    fileName: f.name,
                    fileSize: null,
                    annotationId: null,
                    fileMimeType: f.type,
                    noteText: null
                }
                if (annotation.fileMimeType !== 'application/pdf') {
                    this.$store.dispatch('loader/plusReqCount')
                    imageCompression(f, this.compressOptions)
                        .then(res => {
                                this.uploadFile(res, annotation)
                                this.$store.dispatch('loader/minusReqCount')
                        })
                        .catch(error => console.log(error))
                } else {
                    if((f.size/1024)/1024 >= this.$helper.maxPdfFileSize) {
                        this.$toast.error(
                                `Максимальный размер для файла с расширением .pdf - ${this.$helper.maxPdfFileSize}мб`,
                                "",
                                this.$helper.toast.error
                        )
                        return
                    }
                    this.uploadFile(f, annotation);
                }
            })
        },
        uploadFile(file, annotation) {
            this.$recaptchaLoaded().then(() => {
                this.$recaptcha("AnnotationUpload").then(token => {
                    annotation.fileSize = file.size
                    var fdata = new FormData();
                    fdata.append('file', file)
                    AnnotationService
                        .upload(fdata, token)
                        .then(res => {
                            annotation.annotationId = res.data
                            this.annotationsLocal.push(annotation)
                            this.$emit('update:annotations', this.annotationsLocal)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$toast.error(
                                this.$helper.messages.standartErrorMessage,
                                "",
                                this.$helper.toast.error
                            )
                        })
                });
            });
        },
        getImageUrl(annotation) {
            return  (annotation.fileMimeType === 'application/pdf')
                ? './dist/images/pdf.png'
                : AnnotationService
                    .download(annotation.annotationId, 160, 160)
        }
    }
}
</script>

<style lang="scss" scoped>

$fontsize: 15px;
$maxHeight: 165px;
$maxWidth: $maxHeight;
$trashHeight: 28px;

.annotation-loader-item-box {
    width: $maxWidth;
    height: $maxWidth;
    display: flex;
    justify-content: center;
    align-items: center;
}
.annotation-loader-item-delete {
    width: 35px;
    height: 35px;
    position: absolute;
    background: #DCDFE6;
    border: 4px solid white;
    border-bottom-right-radius: 5px;
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    .annotation-loader-item-delete-icon {
        padding: 2px;
        color: #606266;
        height: $trashHeight;
        width: $trashHeight;
        &:active {
            color: white;
        }
    }
}

.annotation-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $maxWidth;
    height: $maxHeight + 50px;
    .annotation-loader-no-file-icon{
        width: $maxWidth/2.5;
        height: $maxWidth/2.5;
        opacity: .4;
    }
    .annotation-loader-button {
        font-size: $fontsize;
        background-color: #0FA1DB;
        border-color: #0FA1DB;
        color: white;
        &:hover {
            background-color: #118bbb;
            color: white;
        }
    }
}
.VueCarousel{
    max-height: $maxHeight + 32;
    max-width: $maxWidth;
    margin-bottom: 10px;
}
.VueCarousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: $maxWidth;
        max-height: $maxHeight;
        border: 5px solid #ffffff;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }
}
.VueCarousel-inner {
    visibility: visible!important;
    flex-basis: 100%!important;
            margin-right: 10px;
}

.wrapper-annotation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hexagon{
    width: $maxWidth;
    height: $maxHeight;
    border: 5px solid #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>