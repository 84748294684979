<template>
    <div class="main-spinner">
        <moon-loader v-if="true" class="custom-class" :color="color" :loading="true" :size="size" :sizeUnit="`px`"/>
        <div class="main-spinner-title" :style="{color: fontColor}">Обработка данных...</div>
        <div class="main-spinner-text" :style="{color: fontColor}">Пожалуйста, подождите...</div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            required: false,
            default: 40
        },
        color: {
            type: String,
            required: false,
            default: '#3F729B'
        },
        fontColor: {
            type: String,
            required: false,
            default: '#909399'
        }
    }
}
</script>
<style lang="scss" scoped>
.main-spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
}
.main-spinner-title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
}
.main-spinner-text {
    font-size: 14px;
    font-weight: 600;
}
</style>