//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountService = repositoryFactory.get('account')
const AccountTypeServise = repositoryFactory.get('accountType')

//Хелперы.
import {Helper} from '../../helpers/Helper'
import {Enums} from '../../helpers/Enums'

//Модуль хранилища аккаунта.
export default {
    state:{
        account:{
            accountId: null,
            contactSurname: null,
            contactName: null,
            contactMiddleName: null,
            contactGender: null,
            contactBirthDate: null,
            contactMobilePhone: null,
            isRussiaResident: null,
            isLegalEntityOwner: null,
            onlyFreeTasks: null,
            legalEntityType: null,
            accountType: null,
            accountTypeId: null,
            accountTypeUICode: null,
            hasRegistration: null,
            isLegalEntityContracted: null,
            statusCode: null,
            accountSource: null,
            electronicAgreementSigningStatus: null,
            agreement: null,
            agreementId: null,
            referrerChannel: null,
            referrerAccountid: null,
            cooperationType: null,
            dteUser: true
        },
        accountTypes: []
    },
    mutations:{
        setAccount(state, payload) {
            for (let [key, val] of Object.entries(payload)) {
                state.account[key] = val
            }
        },
        setAccountTypes(state, payload) {
            state.accountTypes = payload
        },
        clearAccount(state) {
            for(let [key, val] of Object.entries(state.account)) {
                state.account[key] = null
            }
            state.account.dteUser = true;
        }
    },
    actions:{
        //Заполнить дополнительный поля аккунта значениями.
        setAdditionsAccountFields({commit}, account) {
            return new Promise((resolve, reject) => {
                if (account.accountType && account.accountType.code == Enums.account.ACCOUNT_TYPE.PrivateIndividual)
                    account.hasRegistration = Enums.account.HAS_REGISTRATION.Yes
                if (account.accountType && account.accountType.code == Enums.account.ACCOUNT_TYPE.LegalEntity)
                    account.isLegalEntityContracted = Enums.account.IS_LEGAL_ENTITY_CONTRACTED.No
                account.accountSource = Enums.account.ACCOUNT_SOURCE.SelfRegistration
                if (account.accountType && account.accountType.code == Enums.account.ACCOUNT_TYPE.LegalEntity
                    && account.onlyFreeTasks == Enums.account.ONLY_FREE_TASKS.Yes)
                    account.legalEntityType = Enums.account.LEGAL_ENTITY_TYPE.Legal
                account.statusCode = Enums.account.STATUS_CODES.Draft
                account.onlyFreeTasks = Enums.account.ONLY_FREE_TASKS.No
                commit('setAccount', account)
                resolve()
            })
        },
        //Заполнить аккаунт значениями.
        setAccount({commit}, account) {
            commit('setAccount', account)
        },
        clearAccount({commit}) {
            commit('clearAccount')
        },
        //Подгрузить типы аккаунтов.
        loadAccountTypes({commit}) {
            return new Promise((resolve, reject) => {
                AccountTypeServise
                    .getAccounTypes()
                    .then(response => {
                        if (!response.data || response.data.length <= 0)
                            throw new Helper.UserException("Не удалось подгрузить типы программ.")
                        commit('setAccountTypes', response.data)
                        resolve(response);
                    }).catch(error => { reject(error) })
            })
        },
        //Ссылка на соглашение на профиле и его статус.
        setElectronicAgreement({commit, getters}, ageementId) {
            let account = { ... getters.getAccount }
            account.agreementId = ageementId
            account.electronicAgreementSigningStatus = Enums.account.ELECTRONIC_AGREEMENT_SIGNING_CODES.Signed
            commit('setAccount', account)
        }
    },
    //Геттеры хранилища аккаунта.
    getters: {
        existsAccountTypes(state) {
            return state.accountTypes && state.accountTypes.length > 0
        },
        getAccount: (state) => state.account,
        getAccountTypes: (state) => state.accountTypes
    }
}