<template>
        <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <pageTitle :title="pageTitle" />
                    <buttonNavigation v-if="isEditError" backTo="/"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountRequestService = repositoryFactory.get('accountRequest')

//Компоненты и зависимости.
import pageTitle from '@/components/PageTitle.vue'
import buttonNavigation from '@/components/ButtonNavigation.vue'

export default {
    components: {
        pageTitle,
        buttonNavigation
    },
    data() {
        return {
            account: { ... this.$store.getters.getAccount },
            pageTitle: '',
            isEditError: false
        }
    },
    created() {
        this.$recaptchaLoaded().then(() => {
                this.$recaptcha("getAccountRequestForEdit").then(token => {
                    AccountRequestService
                                .getAccountRequestByAccountPhone(token, this.account.contactMobilePhone)
                                .then(res => {
                                    if (res && res.data) {
                                        try {
                                            if (res.data.account && res.data.account.approvalCount>=1) {
                                                    this.pageTitle = this.$helper.messages.accountRequestEditAccountHasConfirmStatus
                                                    throw new this.$helper.UserException("Вы уже являетесь зарегистрированным пользователем.")
                                            } else if (res.data.account && res.data.account.approvalCount < 1) {
                                                    if (res.data.statusCode == this.$enums.accountRequest.STATUS_CODES.Rejected 
                                                        && res.data.account.statusCode == this.$enums.account.STATUS_CODES.Cancelled) {
                                                        this.$store.dispatch('setIsAccountRequestRejected', false)
                                                    }
                                                    else if (res.data.statusCode != this.$enums.accountRequest.STATUS_CODES.Rejected 
                                                    || res.data.account.statusCode != this.$enums.account.STATUS_CODES.Draft) {
                                                        this.$toast.error(
                                                            this.$helper.messages.accountRequestIsNotReject,
                                                            "",
                                                            this.$helper.toast.error
                                                        )
                                                        this.$store.dispatch('setIsAccountRequestRejected', true)
                                                    } else if (res.data.statusCode == this.$enums.accountRequest.STATUS_CODES.Rejected) {
                                                        this.$store.dispatch('setIsAccountRequestRejected', false)
                                                    }
                                                }
                                                // (res.data.account.statusCode == this.$enums.account.STATUS_CODES.Valid)
                                                // } else if (res.data && res.data.statusCode != this.$enums.accountRequest.STATUS_CODES.Rejected) {
                                                //         this.$toast.error(
                                                //             this.$helper.messages.accountRequestIsNotReject,
                                                //             "",
                                                //             this.$helper.toast.error
                                                //         )
                                                //         let isRej = res.data && res.data.statusCode != this.$enums.accountRequest.STATUS_CODES.Rejected
                                                //         this.$store.dispatch('setIsAccountRequestRejected', isRej)
                                                // }
                                        } catch(e) {
                                            this.$toast.error(e.message, "", this.$helper.toast.error)
                                            this.isEditError = !this.isEditError
                                            this.$store.dispatch('clearAccount')
                                            return
                                        }
                                        this.$store.dispatch('setAccountRequest', res.data)
                                        this.$store.dispatch('setAccount', res.data.account)
                                        this.$router.push({ path: '/profileMain' })
                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                    this.$toast.error(this.$helper.messages.standartErrorMessage, "", this.$helper.toast.error)
                                })
                })
            })
    }
}


</script>
<style lang="scss" scoped>

</style>