<template>
   <div class="wrapper">
        <div class="container">
           <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <div class="information-block">
                        <div class="information-text">Пример фото приведен ниже. Убедитесь, что чек полностью попадает на фото, хорошо освещён, а сама информация читаемая.
                                Чек может быть нулевым, он нужен только для идентификации точки и ее адреса.
                        </div>
                        <div class="information-image-example"><img src='dist/images/invoice_example.png' width="375" height="594" alt=""></div>
                        <buttonNavigation titleBack="Закрыть" :backTo="`/posdetail`"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import buttonNavigation from '@/components/ButtonNavigation.vue'

export default {
    components: {
        buttonNavigation
    }
}
</script>
<style lang="scss" scoped>
.information-block{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.information-image-example{
    margin-top: 10px;
}
.information-text {
    text-align: center;
}
</style>