import baseRepository from '../BaseRepository';

const resource = '/annotation';

//Сервис вложений.
export default {
    //Загрузить вложение.
    upload(formData, token) {
        let methodName = '/Upload'
        return baseRepository.post(`${resource}${methodName}`, formData, { headers: { "re-captcha-token": token } });
    },
    //Получить ссылку на изображение *(скачать изображение).
    download(id, width, height) {
        let method = '/Download'
        let url = `${baseRepository.defaults.baseURL}${resource}${method}/${id}`;
        if (width) {
            url += `?width=${width}&height=${height}`
        }
        return url;
    },
    //Получить ссылку на файл политики.
    policy(document) {
        let methodName = '/Policy';
        return baseRepository.get(`${resource}${methodName}/${document}`);
    }
}