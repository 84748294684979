<template>
   <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <fieldset :disabled="isAccountRequestRejected">
                        <form @submit.prevent="">
                            <pageTitle title="Приложите необходимые документы" />
                            <div >
                                <div class="accordion-item" v-for="(doc, index) in documents" :key="index">
                                    <div class="accordion-item-head" @click="accordionActive(index)">
                                        <div class="accordion-item-head-title">{{doc.name}}</div>
                                        <font-awesome-icon icon="chevron-down" class="accordion-item-head-arrow"/>
                                        <font-awesome-icon icon="chevron-up" class="accordion-item-head-arrow hide"/>
                                    </div>
                                    <div class="accordion-item-body" ref="accordionItemBody">
                                        <div class="accordion-item-body-comment">{{doc.documentTypeComment}}</div>
                                        <annotationsUploader :canDeleteImg="!isAccountRequestRejected" class="accordion-item-body-annotation" ref="documents" v-show="isRequaredDocumentsExists > 0" :isMultiple="true" :annotations.sync="doc.annotations" btnTitle="Добавить фото"/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <windowOpen :content="content" :open="openAgreement"></windowOpen>
                                <div class="agreement">
                                    <div class="custom-control custom-checkbox">
                                        <input v-model="isCheckAgreement" type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                                        <label class="custom-control-label" for="customCheck"></label>
                                        <label class="agreement-title" @click="openAgreement = !openAgreement">Я ознакомился и согласен с условиями соглашения об участии в партнерской программе</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <buttonNavigation :backTo="`/posdetail`" :nextTo="nextTo" :ifNextTo="!isAccountRequestRejected"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import annotationsUploader from '@/components/AnnotationsUploader.vue'
import windowOpen from '@/components/WindowOpen.vue'

export default {
    components: {
        buttonNavigation,
        annotationsUploader,
        pageTitle,
        windowOpen
    },
    data(){
        return {
            isCheckAgreement: this.$store.getters.getIsCheckAgreement,
            documents: [],
            openAgreement: false,
            content: '',
            nextTo: '',
            isAccountRequestRejected: this.$store.getters.getIsAccountRequestRejected
        }
    },
    computed: {
        isRequaredDocumentsExists() {
            return this.$store.getters.getRequiredAccountDocumentSets.length > 0
        }
    },
    methods: {
        //Кривой, но работающий аккордеон !todo *(Вынести в отдельный компонент для многоразового использования.)
        accordionActive: function (index) {
            this.$refs.documents[index].$forceUpdate()
            let accordionItems = this.$refs.accordionItemBody;
            accordionItems.forEach((item, key) => {
                if (key == index) {
                    item.classList.toggle('active')
                    item.parentNode.firstChild.childNodes.forEach((e,index,arr) => {
                        if (index == 0) return
                        e.classList.toggle('hide')
                    })
                }
                else {
                    item.classList.remove('active')
                    item.parentNode.firstChild.childNodes.forEach((e,index,arr) => {
                        if (index == 0) return
                       arr[1].classList.remove('hide')
                       arr[2].classList.add('hide')
                    })
                }
            })
        },
    },
    beforeMount() {
        let accountRequest = { ... this.$store.getters.getAccoutRequest }
        this.nextTo = accountRequest.accountRequestId ? '/thanks' : '/referrer'
        if (this.$store.getters.getAccountDocumentSets.length == 0) {
            this.$store.dispatch('loadAccountDocumentSets')
                .then(() => {
                    if (this.$store.getters.getRequiredAccountDocumentSets.length == 0) {
                        this.$router.push("/thanks")
                        return
                    }
                    this.$store
                        .dispatch('getAgreementForSr', this.$store.getters.getAccount)
                        .then(res => {
                            this.content = this.$store.getters.getAgreement.text
                            this.$store.dispatch('setAnnotationToCurrentDocuments', accountRequest.annotations)
                                .then(() => {
                                    this.documents = [ ... this.$store.getters.getCurrentDocuments ]
                                })
                            this.$store.dispatch('setElectronicAgreement', this.$store.getters.getAgreement.agreementId)
                        })
                        .catch(error => {
                            this.$toast.error(
                                `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                                "",
                                this.$helper.toast.error
                            )
                        })
                })
                .catch( error => {
                    this.$toast.error(
                        `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                        "",
                        this.$helper.toast.error
                    )
                })
        } else {
            this.documents = [ ... this.$store.getters.getCurrentDocuments ]
            this.$store
                        .dispatch('getAgreementForSr', this.$store.getters.getAccount)
                        .then(res => {
                            this.content = this.$store.getters.getAgreement.text
                            this.$store.dispatch('setElectronicAgreement', this.$store.getters.getAgreement.agreementId)
                        })
                        .catch(error => {
                            this.$toast.error(
                                `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                                "",
                                this.$helper.toast.error
                            )
                        })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('setIsCheckAgreement', this.isCheckAgreement)
        if (to.path == '/posdetail') {
            this.$store.dispatch('setDocuments', this.documents)
            next()
            return
        }
        if (this.documents.length > 0 && !this.documents.every(el => el.annotations.length > 0)) {
            this.$toast.error(
                this.$helper.messages.needAllDocumentsAttachment,
                "",
                this.$helper.toast.error
            )
            return
        }   else if (!this.isCheckAgreement) {
            this.$toast.error(
                this.$helper.messages.needAgreement,
                "",
                this.$helper.toast.error
            )
        }
        else {
            this.$store.dispatch('setAccountRequestAnnotations', this.documents)
            next()
        }
    }
}
</script>
<style lang="scss" scoped>
.accordion-item-head-title {
    font-weight: 550;
}

.agreement {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .agreement-title {
        margin-left: 10px;
        font-weight: 500;
        color: #0d47a1;
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            color: #f44242;
        }
    }
}

.accordion-item {
    display: flex;
    flex-direction: column;
}

.accordion-item-head{
    margin-top: 10px;
	background-color: #dfdfdf;
	border-top-left-radius: 5px ;
	border-top-right-radius: 5px;
	cursor: pointer;
    padding:10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordion-item-head-arrow{
    margin-left: 10px;
}
.accordion-item-head-arrow.hide {
    display: none;
}

.accordion-item-body{
    padding-bottom: 10px;
    display: none;
    margin-bottom: 10px;
    height: auto;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.accordion-item-body-comment {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
}
.accordion-item-body-annotation {
    margin-bottom: 15px;
}
.accordion-item-body.active  {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s;
}

</style>