<template>
    <div class="wrapper">
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="col-sm-9">
                        <pageTitle title="Выберите действие" />
                        <div class="navigation-box">
                            <div class="navigation-buttons">
                                <button class="btn btn-primary button-navigation" @click="newSelfReg">
                                    Новое обращение
                                </button>
                                <button class="btn btn-primary button-navigation" @click="editSelfReg">
                                    Изменить прошлое обращение
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal 
                v-if="isModal && $helper.isUatNode && isUatModalShow" 
                title="Предупреждение!" 
                :bodyTemplate="bodyTemplate" 
                forExitText='Продолжить на тесте' 
                @closeModal="isModal = !isModal"/>
    </div>
</template>
<script>

//Компоненты и зависимости.
import pageTitle from '@/components/PageTitle.vue'
import modal from '@/components/Modal.vue'


export default {
    components: {
        pageTitle,
        modal
    },
    data() {
        return {
            isModal: true,
            isUatModalShow: this.$store.getters.getisUatModalShow,
            bodyTemplate: this.$helper.messages.bodyTemplateUatHtml
        }
    },
    methods: {
        newSelfReg() {
            this.$store.dispatch('setisUatModalShow', false)
            this.$router.push({ name: 'profileMain' })
        },
        editSelfReg() {
            this.$store.dispatch('setisUatModalShow', false)
            this.$router.push({ name: 'existsPhone' })
        }
    }
}
</script>
<style lang="scss" scoped>
.button-navigation {
    min-width: 120px;
    margin-bottom: 20px;
    background-color: #0FA1DB;
    border-color: #0FA1DB;
    color: white;
    &:hover {
        background-color: #118bbb;
        color: white;
    }
    .button-navigation-item-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .button-navigation-item-content-title {
            padding: 0 7px;
        }
    }
    &.button-position {
        margin: 0 10px;
    }
}
.navigation-box {
    display: flex;
    justify-content: center;
}
.navigation-buttons {
    display: flex;
    flex-direction: column;
}
</style>