<template>
    <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <pageTitle title="Дополнительная информация" />
                    <fieldset :disabled="isAccountRequestRejected">
                        <form @submit.prevent="">
                            <div class="form-group" :class="{ errorInput: $v.account.isRussiaResident.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="flag" class="icon"/><label class="country">Я - гражданин РФ:</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.isRussiaResident.required">Поле я - гражданин РФ обазательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.isRussiaResident"
                                    @change="$v.account.isRussiaResident.$touch()"
                                    :class="{ 'is-invalid': $v.account.isRussiaResident.$error, error: $v.account.isRussiaResident.$error }"
                                    :value="$enums.account.IS_RU_RESIDENT.Yes" type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline1">Да</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.isRussiaResident"
                                    @change="$v.account.isRussiaResident.$touch()"
                                    :class="{ 'is-invalid': $v.account.isRussiaResident.$error, error: $v.account.isRussiaResident.$error }"
                                    :value="$enums.account.IS_RU_RESIDENT.No" type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline2">Нет</label>
                                </div>
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.accountType.$error }">
                                    <font-awesome-icon icon="user" class="icon"/><label>Моя роль в торговой точке:</label>
                                    <p class="errorText" v-if="!$v.accountType.required">Поле моя роль в торговой точке обязательно</p>
                                    <select v-model="accountType"
                                    @change="$v.accountType.$touch()"
                                    :class="{ 'is-invalid': $v.accountType.$error, error: $v.accountType.$error }"
                                    class="form-control">
                                        <option style="display: none"></option>
                                        <option :value="$enums.account.ACCOUNT_TYPE.PrivateIndividual">Продавец</option>
                                        <option :value="$enums.account.ACCOUNT_TYPE.LegalEntity">Представитель ЮЛ</option>
                                    </select>
                            </div>
                            <!-- <div class="form-group" :class="{ errorInput: $v.account.onlyFreeTasks.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="file" class="icon"/><label class="country">Я готов предоставить документы (обязательное условие начисления баллов в программе):</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.onlyFreeTasks.required">Поле я готов предоставить документы... обязательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.onlyFreeTasks"
                                    @change="$v.account.onlyFreeTasks.$touch()"
                                    :class="{ 'is-invalid': $v.account.onlyFreeTasks.$error, error: $v.account.onlyFreeTasks.$error }"
                                    :value="$enums.account.ONLY_FREE_TASKS.No" type="radio" id="customRadioInline4" name="customRadioInline4" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline4">Да</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.onlyFreeTasks"
                                    @change="$v.account.onlyFreeTasks.$touch()"
                                    :class="{ 'is-invalid': $v.account.onlyFreeTasks.$error, error: $v.account.onlyFreeTasks.$error }"
                                    :value="$enums.account.ONLY_FREE_TASKS.Yes" type="radio" id="customRadioInline3" name="customRadioInline4" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline3">Нет</label>
                                </div>
                            </div> -->
                            <div v-if="showLegalType" class="form-group" :class="{ errorInput: $v.account.isLegalEntityOwner.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="user-tie" class="icon"/><label class="country">Я - владелец точки:</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.isLegalEntityOwner.required">Поле Я - владелец точки обязательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.isLegalEntityOwner"
                                    @change="$v.account.isLegalEntityOwner.$touch()"
                                    :class="{ 'is-invalid': $v.account.isLegalEntityOwner.$error, error: $v.account.isLegalEntityOwner.$error }"
                                    :value="$enums.account.IS_OWNER.Yes" type="radio" id="customRadioInline5" name="customRadioInline5" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline5">Да</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.isLegalEntityOwner"
                                    @change="$v.account.isLegalEntityOwner.$touch()"
                                    :class="{ 'is-invalid': $v.account.isLegalEntityOwner.$error, error: $v.account.isLegalEntityOwner.$error }"
                                    :value="$enums.account.IS_OWNER.No" type="radio" id="customRadioInline6" name="customRadioInline5" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline6">Нет</label>
                                </div>
                            </div>
                            <div v-if="showLegalType" class="form-group" :class="{ errorInput: $v.account.legalEntityType.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="user-tie" class="icon"/><label class="country">Владелец точки - индивидуальный предприниматель:</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.legalEntityType.required">Поле Владелец точки - индивидуальный предприниматель обязательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.legalEntityType"
                                    @change="$v.account.legalEntityType.$touch()"
                                    :class="{ 'is-invalid': $v.account.legalEntityType.$error, error: $v.account.legalEntityType.$error }"
                                    :value="$enums.account.LEGAL_ENTITY_TYPE.IndividualEntrepreneur" type="radio" id="customRadioInline7" name="customRadioInline7" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline7">Да</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.legalEntityType"
                                    @change="$v.account.legalEntityType.$touch()"
                                    :class="{ 'is-invalid': $v.account.legalEntityType.$error, error: $v.account.legalEntityType.$error }"
                                    :value="$enums.account.LEGAL_ENTITY_TYPE.Legal" type="radio" id="customRadioInline8" name="customRadioInline7" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline8">Нет</label>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <buttonNavigation :backTo="`/profileMain`" :nextTo="`/posdetail`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Компоненты и зависимости.
import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import { required } from 'vuelidate/lib/validators'

//Репозитории.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сарвисы.
const AccountRequestService = repositoryFactory.get('accountRequest')


export default {
        components: {
        buttonNavigation,
        pageTitle
    },
    data() {
        return {
            account: { ... this.$store.getters.getAccount },
            accountType: this.$store.getters.getAccount.accountType ? this.$store.getters.getAccount.accountType.code : null,
            isAccountRequestRejected: this.$store.getters.getIsAccountRequestRejected
        }
    },
    validations() {
        if (this.showLegalType) {
            return {
                account: {
                    isRussiaResident: {
                        required
                    },
                    isLegalEntityOwner: {
                        required
                    },
                    legalEntityType: {
                        required
                    },
                },
                accountType: {
                    required
                }
            }
        } else {
            return {
                account: {
                    isRussiaResident: {
                        required
                    },
                },
                accountType: {
                    required
                }
            }
        }
    },
    computed: {
        showLegalType() {
            return this.accountType == this.$enums.account.ACCOUNT_TYPE.LegalEntity
        }
    },
    watch: {
        showLegalType() {
                this.account.isLegalEntityOwner = null
                this.account.legalEntityType = null
                this.account.onlyFreeTasks = null
                this.account.isLegalEntityContracted = null
                this.account.electronicAgreementSigningStatus = null
                this.account.hasRegistration = null
                this.$store.dispatch('setAccount', this.account)
                this.$store.dispatch('setIsCheckAgreement', false)
        },
        accountType() {
            let typeId = null,
                type = null,
                accountTypes = this.$store.getters.getAccountTypes;
            switch(this.accountType) {
                case this.$enums.account.ACCOUNT_TYPE.LegalEntity:
                        type = accountTypes.find((item, index) => {
                                    return item.code == this.$enums.account.ACCOUNT_TYPE.LegalEntity
                                })
                        typeId = type.accountTypeId
                    break
                case this.$enums.account.ACCOUNT_TYPE.PrivateIndividual:
                        type = accountTypes.find((item, index) => {
                                    return item.code == this.$enums.account.ACCOUNT_TYPE.PrivateIndividual
                                })
                        typeId = type.accountTypeId
                    break
            }
            this.account.accountTypeId = typeId
            this.account.accountType = type
        }
    },
    beforeRouteLeave(to, from, next){
        this.$store.dispatch('setAdditionsAccountFields', this.account)
            .then(() => {
                this.$store.dispatch('setAccoutToAccountRequest', this.$store.getters.getAccount)
        })
        if (to.path == '/profileMain') {
            next()
            return
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.$toast.error(
                this.$helper.messages.fieldsAreRequared,
                "",
                this.$helper.toast.error
            )
            return
        }
        next()
    },
    created() {
        if (!this.$store.getters.existsAccountTypes)
            this.$store.dispatch('loadAccountTypes')
                .then()
                .catch( error => {
                    this.$toast.error(
                        `${this.$helper.messages.standartErrorMessage} ${error.message}`,
                        "",
                        this.$helper.toast.error
                    )
            })
    },
    methods: {
        getUiCode() {
            let type = this.account.accountType
        }
    }
}
</script>

<style lang="scss" scoped>
.radio-row-label {
    display: flex;
    flex-direction: row;
}
.country {
    margin-right: 15px;
}
</style>