<template>
</template>
<script>

export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String
        }
    },
    data() {
        return {
            windowRef: null,
        }
    },
    watch: {
        open() {
            this.windowOpen()
        }
    },
    methods: {
        windowOpen() {
            this.windowRef = window.open('', `${Date.now().toString()}`, '');
            let content = `<div style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;">
                                <div style="padding: 10px;">${this.content}</div>
                                <button id="btnClose" style="font-size: 16px; margin-top: 30px; margin-bottom: 50px" class="button-close" onclick="window.close()">Согласен</button>
                           </div>`
            this.windowRef.document.write(content)
            if (document.documentElement.clientWidth <= 900) {
                this.windowRef.document.getElementById("btnClose").style.fontSize = "50px";
            }
            this.windowRef.document.head.innerHTML = '<title>Текст соглашения</title>'
        }
    },
}
</script>