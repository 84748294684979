<template>
<div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <pageTitle title="Основная информация" />
                    <fieldset :disabled="isAccountRequestRejected">
                        <form @submit.prevent="">
                            <div class="form-group" :class="{ errorInput: $v.account.contactSurname.$error }">
                                <font-awesome-icon icon="user" class="icon" /><label>Фамилия:</label>
                                <p class="errorText" v-if="!$v.account.contactSurname.required">Поле фамилия обязательно</p>
                                <input v-model="account.contactSurname"
                                       :class="{ 'is-invalid': $v.account.contactSurname.$error,
                                        error: $v.account.contactSurname.$error}"
                                       @change="$v.account.contactSurname.$touch()"
                                       type="text"
                                       class="form-control"
                                       placeholder="Введите фамилию">
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.account.contactName.$error }">
                                <font-awesome-icon icon="user" class="icon" /><label>Имя:</label>
                                <p class="errorText" v-if="!$v.account.contactName.required">Поле имя обязательно</p>
                                <input v-model="account.contactName"
                                       :class="{ 'is-invalid': $v.account.contactName.$error, error: $v.account.contactName.$error }"
                                       @change="$v.account.contactName.$touch()"
                                       type="text" class="form-control" placeholder="Введите имя">
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.account.contactMiddleName.$error }">
                                <font-awesome-icon icon="user" class="icon" /><label>Отчество:</label>
                                <p class="errorText" v-if="!$v.account.contactMiddleName.required">Поле отчество обязательно</p>
                                <input v-model="account.contactMiddleName"
                                       :class="{ 'is-invalid': $v.account.contactMiddleName.$error, error: $v.account.contactMiddleName.$error }"
                                       @change="$v.account.contactMiddleName.$touch()"
                                       type="text" class="form-control" placeholder="Введите отчество">
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.account.contactGender.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="venus-mars" class="icon" /><label class="gender">Пол:</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.contactGender.required">Поле пол обязательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.contactGender" :value="$enums.account.GENDERS.Male"
                                           :class="{ 'is-invalid': $v.account.contactGender.$error, error: $v.account.contactGender.$error }"
                                           @change="$v.account.contactGender.$touch()"
                                           type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline1">Мужской</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.contactGender" :value="$enums.account.GENDERS.Female"
                                           :class="{ 'is-invalid': $v.account.contactGender.$error, error: $v.account.contactGender.$error }"
                                           @change="$v.account.contactGender.$touch()"
                                           type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline2">Женский</label>
                                </div>
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.account.cooperationType.$error }">
                                <div class="radio-row-label">
                                    <font-awesome-icon icon="store" class="icon" /><label class="gender">Выберите тип магазина:</label>
                                </div>
                                <p class="errorText" v-if="!$v.account.cooperationType.required">Поле тип магазина обязательно</p>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.cooperationType" :value="$enums.account.COOPERATIONTYPE.Retail"
                                           :class="{ 'is-invalid': $v.account.cooperationType.$error, error: $v.account.cooperationType.$error }"
                                           @change="$v.account.cooperationType.$touch()"
                                           type="radio" id="customRadioInline3" name="customRadioInline3" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline3">В магазине продаются сигареты или системы нагревания табака разных производителей</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="account.cooperationType" :value="$enums.account.COOPERATIONTYPE.QPartner"
                                           :class="{ 'is-invalid': $v.account.cooperationType.$error, error: $v.account.cooperationType.$error }"
                                           @change="$v.account.cooperationType.$touch()"
                                           type="radio" id="customRadioInline4" name="customRadioInline4" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadioInline4">Q-Partner - в магазине продаётся только никотиносодержащая продукция Philip Morris</label>
                                </div>
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.birthDate.$error }">
                                <font-awesome-icon icon="calendar-alt" class="icon" /><label>Дата рождения:</label>
                                <p class="errorText" v-if="!$v.birthDate.required">Поле дата рождения обязательно</p>
                                <p class="errorText" v-if="!$v.birthDate.ageMinVaditation">
                                    Регистрация доступна только при достижении {{getMinAgeByCooperationType}} {{getMinAgeByCooperationType == 18 ? "лет" : "года"}}
                                </p>
                                <p class="errorText" v-if="!$v.birthDate.ageMaxVaditation && $v.birthDate.required">Некорректная дата рождения</p>
                                <vue-mask id="date-masked-selector" mask="00.00.0000" class="form-control" :raw="false" v-if="isAndroid"
                                          :class="{ 'is-invalid': $v.birthDate.$error, error: $v.birthDate.$error}" inputmode="numeric"
                                          v-model="birthDateText" placeholder="дд.мм.гггг"/>
                                <input id="dob-input" class="form-control" type="date"
                                       v-model="birthDate" v-if="!isAndroid"
                                       :class="{ 'is-invalid': $v.birthDate.$error, error: $v.birthDate.$error}"
                                       @change="$v.birthDate.$touch()" width="0" height="0" />
                            </div>
                            <div class="form-group" :class="{ errorInput: $v.phone.$error }">
                                <font-awesome-icon icon="phone" class="icon" /><label>Номер телефона:</label>
                                <p class="errorText" v-if="!$v.phone.required">Поле номер телефона обязательно</p>
                                <p class="errorText" v-if="!$v.phone.isPhone">Некорректный формат номера телефона. Введите последовательно 10 цифр.</p>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" id="inputGroupPrepend2">+7</div>
                                    </div>
                                    <input type="number" @change="$v.phone.$touch(); onPhoneChange()"
                                           v-model="phone"
                                           :class="{ 'is-invalid': $v.phone.$error, error: $v.phone.$error }"
                                           class="form-control" placeholder="NNNNNNNNNN">
                                </div>

                            </div>
                        </form>
                    </fieldset>
                    <div class="policy">
                        <font-awesome-icon icon="file-download" class="icon"/><a href="#" @click.prevent="downloadPolicyPersonalDataProcessing()" download>Политика об обработке персональных данных</a>
                    </div>
                    <buttonNavigation backTo="/" :titleTo="buttonNavigationTitle" :nextTo="buttonNavigationRoute"/>
                </div>
            </div>
        </div>
</div>
</template>

<script>

//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountService = repositoryFactory.get('account')
const AccountTypeServise = repositoryFactory.get('accountType')
const AnnotationService = repositoryFactory.get('annotation')


//Компоненты и зависимости.
import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'
import { required, helpers } from 'vuelidate/lib/validators'
import {Helper} from '@/helpers/Helper'
import { RetailMinAge, QPartnerMinAge } from '@/helpers/Constants'


export default {
    components: {
        buttonNavigation,
        datepicker,
        pageTitle
    },
    data () {
        return {
            ru,
            phone: '',
            account: { ... this.$store.getters.getAccount },
            isAccountChange: false,
            birthDate: '',
            buttonNavigationTitle: 'Получить код',
            buttonNavigationRoute: '/verification',
            isAccountRequestRejected: this.$store.getters.getIsAccountRequestRejected,
            isAndroid: false,
            birthDateText: ''
        }
    },
    mounted() {
        let accout = this.$store.getters.getAccount;
            if (accout.contactBirthDate)
               this.birthDate = this.$moment(accout.contactBirthDate).format('yyyy-MM-DD')
            if (accout.contactMobilePhone)
            this.phone = accout.contactMobilePhone.replace('+7', '')
        this.isAndroid = /Android/i.test(navigator.userAgent);
    },
    watch: {
        birthDate() {
            this.account.contactBirthDate = new Date(this.birthDate)
        },
        phone() {
            this.account.contactMobilePhone = `+7${this.phone}`
            let isPhoneChanged = `+7${this.phone}` != this.$store.getters.getAccount.contactMobilePhone
            this.buttonNavigationTitle =  isPhoneChanged ? 'Получить код' : 'Далее'
            this.buttonNavigationRoute = isPhoneChanged ? '/verification' : '/profiledetail'
        },
        birthDateText() {
            console.log(this.birthDateText);
            var spliter = this.birthDateText.split('.');
            this.birthDate = null;
            if (!!spliter && spliter.length == 3) {
                var newBirthDate = new Date(Date.UTC(spliter[2], spliter[1] - 1, spliter[0]));
                var date = newBirthDate.getDate();
                if (date < 10) {
                    date = '0' + date;
                }
                var month = newBirthDate.getMonth() + 1;
                if (month < 10) {
                    month = '0' + month;
                }
                var dateValidator = date + "." + month + "." + newBirthDate.getFullYear();
                if (this.birthDateText == dateValidator) {
                    this.birthDate = newBirthDate;
                }
            }
        }
    },
    //Валидатор.
    validations() {
        return {
            account: {
                contactSurname: {
                    required
                },
                contactName: {
                    required
                },
                contactMiddleName: {

                },
                contactGender: {
                    required
                },
                cooperationType: {
                    required
                }                
            },
            birthDate: {
                required,
                ageMinVaditation: Helper.customValidation.minAge(this.getMinAgeByCooperationType),
                ageMaxVaditation: Helper.customValidation.maxAge
            },
            phone: {
                required,
                isPhone: Helper.customValidation.phone
            }
        }
    },
    computed: {
        getMinAgeByCooperationType() {
            return this.account.cooperationType == this.$enums.account.COOPERATIONTYPE.Retail 
                ? RetailMinAge
                : QPartnerMinAge;
        }
    },
    methods: {
        //Проверочка. Не будем же постоянно заново проверять в базе телефон.
        onPhoneChange() {
            this.isAccountChange = this.phone != this.$store.getters.getAccount.contactMobilePhone
        },
        //Ссылка на файл политики об обработки персональных данных
        downloadPolicyPersonalDataProcessing() {
            AnnotationService.policy(this.$enums.annotations.POLICY.PersonalDataProcessing)
                .then(response => {
                    const body = Helper.annotation.decodeBase64(response.data.body);
                    const blob = new Blob([body], {
                        type: response.data.type
                    });

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = response.data.name;
                    link.click();

                    URL.revokeObjectURL(link.href);
                })
                .catch(error => {
                    this.$toast.error(
                            `${this.$helper.messages.fileDownloadErrorMessage}`,
                            "",
                            this.$helper.toast.error);
                    console.log(error.message);
                });
        }
    },
    beforeRouteLeave (to, from, next) {
        if (to.name == "main") {
            this.$store.dispatch('clearAccount')
            next()
            return
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.$toast.error(
                this.$helper.messages.fieldsAreRequared,
                "",
                this.$helper.toast.error
            )
            return
        }

        if (!this.isAccountChange) {
            this.$store.dispatch('setAccount', this.account)
            next()
        }
        else {
            //Проверить есть ли уже аккаунт в системе.
            this.$recaptchaLoaded().then(() => {
                this.$recaptcha("profileMain").then(token => {
                    AccountService
                                .getAccountByPhone(token, this.account.contactMobilePhone)
                                .then(res => {
                                    if (res && res.data) {
                                        this.$toast.error(
                                            this.$helper.messages.errorAccountExistMessage,
                                            "",
                                            this.$helper.toast.error
                                        )
                                    }
                                    else {
                                        this.$store.dispatch('setAccount', this.account)
                                        next()
                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                    this.$toast.error(
                                        this.$helper.messages.standartErrorMessage,
                                        "",
                                        this.$helper.toast.error
                                    )
                                })
                })
            })
        }
    },
}

</script>

<style lang="scss" scoped>

.radio-row-label {
    display: flex;
    flex-direction: row;
}
.gender {
    margin-right: 15px;
}
.form-control::placeholder {
    opacity: .6;
}
.custom-control-label{
    font-size: 0.9em;
}
.custom-date-input {
    display: flex;
    border-radius: 0.25em;
    border: 1px #ced4da solid;
    overflow: hidden;
    background: #fff;
    padding: 6px 12px;
}
.custom-date-input > * {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.custom-date-input.is-invalid {
    border-color: #dc3545;
}
.date-input-part,
.date-input-part:hover,
.date-input-part:focus,
.date-input-part::-webkit-outer-spin-button,
.date-input-part::-webkit-inner-spin-button{
    appearance: none;
    border: none;
}
.date-input-day,
.date-input-month{
    width:27px;
    text-align: center;
}
.date-input-year{
    width:44px;
}
.custom-date-input-dot {
    background-color: #fff;
}

.policy {
    margin-top: 10px;
    text-align: center;
}

.policy a {
    color: #928d8d;
}

</style>