//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AgreementService = repositoryFactory.get('agreement')

//Хелперы.
import {Enums} from '../../helpers/Enums'
import {Helper} from '../../helpers/Helper'

//Модуль хранилища подписанных соглашений.
export default {
    state: {
        signedAgreement: {
            accountId: null,
            agreementId: null,
            isSigned: Enums.signedAgreement.IS_SIGNED.Yes
        },
        agreement: {}
    },
    mutations: {
        setAgreement(state, payload) {
            state.agreement = payload
        },
        setSignedAgreementField(state, payload) {
            state.signedAgreement.agreementId = payload.agreementId
            state.signedAgreement.accountId = payload.accountId
        }
    },
    actions: {
        //Подгрузить соглашение по заданным параметрам.
        getAgreementForSr({commit}, account) {
            return new Promise((resolve, reject) => {
                AgreementService
                    .getAgreementForSr(account.onlyFreeTasks, account.accountType.code, account.isLegalEntityContracted, account.cooperationType)
                    .then(response => {
                        if (!response.data || response.data.length <= 0)
                            throw new Helper.UserException("Не удалось подгрузить соглашение.")
                        commit('setAgreement', response.data)
                        resolve(response);
                    }).catch(error => { reject(error) })
            })
        },
        setSignedAgreementField({commit}, data) {
            commit('setSignedAgreementField', data)
        }
    },
    getters: {
        getAgreement: state => state.agreement,
        getSignedAgreement: state => state.signedAgreement
    }
}