<template>
   <div class="wrapper">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9">
                    <pageTitle title="Откуда вы узнали о платформе КУспеху?" />
                    <form @submit.prevent="">
                        <div class="form-group">
                            <div class="custom-control custom-radio">
                                <p class="errorText" v-if="!$v.account.referrerChannel.required">Поле пол обязательно</p>
                                <input v-model="account.referrerChannel" :value="$enums.account.REFERRER_CHANNELS.Pmsm"
                                :class="{ 'is-invalid': $v.account.referrerChannel.$error, error: $v.account.referrerChannel.$error }"
                                @change="$v.account.referrerChannel.$touch()"
                                type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
                                <label class="custom-control-label" for="customRadioInline1">От представителя Филип Моррис</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input v-model="account.referrerChannel" :value="$enums.account.REFERRER_CHANNELS.Friend"
                                :class="{ 'is-invalid': $v.account.referrerChannel.$error, error: $v.account.referrerChannel.$error }"
                                @change="$v.account.referrerChannel.$touch()"
                                type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                                <label class="custom-control-label" for="customRadioInline2">От друга-коллеги*</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input v-model="account.referrerChannel" :value="$enums.account.REFERRER_CHANNELS.MarketingCampaign"
                                :class="{ 'is-invalid': $v.account.referrerChannel.$error, error: $v.account.referrerChannel.$error }"
                                @change="$v.account.referrerChannel.$touch()"
                                type="radio" id="customRadioInline3" name="customRadioInline1" class="custom-control-input">
                                <label class="custom-control-label" for="customRadioInline3">Реклама / промо-акция / брошюра</label>
                            </div>
                            <div class="help-description">
                                друг- сотрудник или владелец магазина, в котором реализуется табачная и/или никотинсодержащая продукция компании "Филип Моррис".
                            </div>
                        </div>
                        <div class="form-group" v-show="account.referrerChannel == $enums.account.REFERRER_CHANNELS.Friend" :class="{ errorInput: $v.phone.$error }">
                            <font-awesome-icon icon="phone" class="icon"/><label>Укажите номер телефона друга:</label>
                            <div :class="['help-description', {'phoneCheckError' : checkReferrerPhoneCount >= 1}]">
                                Если вы не знаете номер телефона друга, то оставьте поле пустым и нажмите Отправить.
                            </div>
                            <p class="errorText" v-if="!$v.phone.isPhone">Некорректный формат номера телефона. Введите последовательно 10 цифр.</p>
                            <p :class="['help-description', 'phoneCheckError', {'phoneCheckNone' : checkReferrerPhoneCount < $helper.CheckReferrerPhoneCountValue}]">Вы иcчерпали все попытки ввода номера. Нажмите Отправить.</p>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="inputGroupPrepend2">+7</div>
                                </div>
                                <input type="number" :disabled="checkReferrerPhoneCount >= $helper.CheckReferrerPhoneCountValue"
                                @change="$v.phone.$touch();"
                                v-model="phone" :class="{ 'is-invalid': $v.phone.$error, error: $v.phone.$error }"
                                    class="form-control" placeholder="NNNNNNNNNN">
                            </div>
                        </div>
                        <buttonNavigation :titleTo="`Отправить`" :backTo="`/documents`" :nextTo="`/thanks`"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountService = repositoryFactory.get('account')

//Компоненты и зависимости.
import buttonNavigation from '@/components/ButtonNavigation.vue'
import pageTitle from '@/components/PageTitle.vue'
import { required, helpers } from 'vuelidate/lib/validators'
import {Helper} from '@/helpers/Helper'

export default {
    components: {
        buttonNavigation,
        pageTitle
    },
    data() {
        return {
            account: { ... this.$store.getters.getAccount },
            phone: this.$store.getters.getReferrerMobilePhone,
        }
    },
    computed: {
        checkReferrerPhoneCount() {
            return this.$store.getters.getCheckReferrerPhoneCount
        },
    },
    watch: {
        account: {
            handler: function(val) {
                if (val.referrerChannel != this.$enums.account.REFERRER_CHANNELS.Friend) {
                    this.phone = ''
                }
            },
            deep: true
        }
    },
    validations: {
        account: {
            referrerChannel: {
                required
            }
        },
        phone: {
            isPhone: Helper.customValidation.phone
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$v.phone.$touch();
        if (this.$v.phone.$invalid) {
            this.$toast.error(
                this.$helper.messages.fieldsAreRequared,
                "",
                this.$helper.toast.error
            )
            return
        }
        this.$store.dispatch('setAccount', this.account);
        this.$store.dispatch('setReferrerMobilePhone', this.phone)
        if (to.path == '/documents') {
            next()
            return
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.$toast.error(
                this.$helper.messages.fieldsAreRequared,
                "",
                this.$helper.toast.error
            )
            return
        }
        if (this.phone) {
            if (this.$store.getters.getCheckReferrerPhoneCount >= this.$helper.CheckReferrerPhoneCountValue) {
                this.$toast.error(
                    this.$helper.messages.errorCountLimit,
                    "",
                    this.$helper.toast.error
                )
                this.phone = ''
                return
            } else {
                this.$recaptchaLoaded().then(() => {
                    this.$recaptcha("referrer").then(token => {
                        let referrerPhone = `+7${this.$store.getters.getReferrerMobilePhone}`
                        AccountService
                            .getAccountByPhone(token,
                                referrerPhone,
                                [this.$enums.account.STATUS_CODES.Valid, this.$enums.account.STATUS_CODES.Updated],
                                this.account.contactMobilePhone)
                            .then(res => {
                                if (!res.data) {
                                    this.$toast.error(
                                        this.$helper.messages.errorAccountReferrerExist,
                                        "",
                                        this.$helper.toast.error
                                    )
                                    this.$store.dispatch('setCheckReferrerPhoneCount', this.checkReferrerPhoneCount+1)
                                    if (this.$store.getters.getCheckReferrerPhoneCount >= this.$helper.CheckReferrerPhoneCountValue) {
                                        this.phone = ''
                                        this.$store.dispatch('setReferrerMobilePhone', this.phone)
                                    }
                                }
                                else {
                                    this.account.referrerAccountid = res.data
                                    this.$store.dispatch('setAccount', this.account);
                                    next()
                            }})
                            .catch(error => {
                                console.log(error)
                                this.$toast.error(
                                    this.$helper.messages.standartErrorMessage,
                                    "",
                                    this.$helper.toast.error
                                )
                            })
                    })
                })
            }
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>

.help-description {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
}

.phoneCheckError {
    color: #af2c2c;
}
.phoneCheckNone {
    display: none;
}
</style>