<template>
    <div class="wrapper">
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="col-sm-9">
                        <pageTitle title="Введите номер телефона" />
                        <form @submit.prevent="">
                            <div class="form-group" :class="{ errorInput: $v.phone.$error }">
                            <font-awesome-icon icon="phone" class="icon"/><label>Номер телефона:</label>
                            <p class="errorText" v-if="!$v.phone.required">Поле номер телефона обязательно</p>
                            <p class="errorText" v-if="!$v.phone.isPhone">Некорректный формат номера телефона. Введите последовательно 10 цифр.</p>
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" id="inputGroupPrepend2">+7</div>
                            </div>
                            <input type="number" @change="$v.phone.$touch();"
                                v-model="phone"
                                :class="{ 'is-invalid': $v.phone.$error, error: $v.phone.$error }"
                                class="form-control" placeholder="NNNNNNNNNN">
                            </div>

                        </div>
                        </form>
                        <buttonNavigation backTo="/" nextTo="/verification" titleTo="Получить код"/>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
//Репозиторий.
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountRequestService = repositoryFactory.get('accountRequest')


//Компоненты и зависимости.
import pageTitle from '@/components/PageTitle.vue'
import buttonNavigation from '@/components/ButtonNavigation.vue'
import { required, helpers } from 'vuelidate/lib/validators'
import {Helper} from '@/helpers/Helper'

export default {
    components: {
        pageTitle,
        buttonNavigation,
    },
    data() {
        return {
            account: { ... this.$store.getters.getAccount },
            phone: '',
        }
    },
    //Валидатор.
    validations: {
        phone: {
                required,
                isPhone: Helper.customValidation.phone
        }
    },
    watch: {
        phone() {
            this.account.contactMobilePhone = `+7${this.phone}`
        }
    },
    beforeRouteLeave (to, from, next) {
        if (to.name == "main") {
            this.$store.dispatch('clearAccount')
            next()
            return
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.$toast.error(
                this.$helper.messages.fieldsAreRequared,
                "",
                this.$helper.toast.error
            )
            return
        }
        this.$store.dispatch('setAccount', this.account)
        this.$recaptchaLoaded().then(() => {
                this.$recaptcha("existsPhone").then(token => {
                    AccountRequestService
                                .getAccountRequestIdByAccountPhone(token, this.account.contactMobilePhone)
                                .then(res => {
                                    if (!res || !res.data) {
                                        this.$toast.error(
                                            this.$helper.messages.accountRequestNotFound,
                                            "",
                                            this.$helper.toast.error
                                        )
                                        return
                                    }
                                    else {
                                        this.$store.dispatch('setFromRouteToVerification', '/existsPhone')
                                        next()
                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                    this.$toast.error(
                                        this.$helper.messages.standartErrorMessage,
                                        "",
                                        this.$helper.toast.error
                                    )
                                    return
                                })
                })
            })
    }
}
</script>
<style lang="scss" scoped>

</style>