<template>
    <div class="button-navigation">
        <router-link
            v-if="backShow"
            :to="backTo"
            v-slot="{ href, navigate}">
            <button :href="href" @click="navigate" class="btn btn-primary button-navigation-item" :class="{'button-position': backShow && nextShow}">
                <div class="button-navigation-item-content">
                    <font-awesome-icon icon="arrow-left"/>
                    <div class="button-navigation-item-content-title">{{titleBack}}</div>
                </div>
            </button>
        </router-link>
        <router-link
            v-if="nextShow"
            :to="nextTo"
            v-slot="{ href, navigate}">
            <button :href="href" @click="navigate" class="btn button-navigation-item" :class="{'button-position': backShow && nextShow}">
                <div class="button-navigation-item-content">
                    <div class="button-navigation-item-content-title">{{titleTo}}</div>
                    <font-awesome-icon icon="arrow-right"/>
                </div>
            </button>
        </router-link>
    </div>
</template>

<script>

export default {
    data() {
        return {
            nextShow: false,
            backShow: false
        }
    },
    props: {
        nextTo: {
            type: String,
            required: false
        },
        backTo: {
            type: String,
            required: false
        },
        titleTo: {
            type: String,
            default: 'Далее'
        },
        titleBack: {
            type: String,
            default: 'Назад'
        },
        ifNextTo: {
            type: Boolean,
            required: false,
            default: true
        },
        ifBackTo: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted() {
        if (this.nextTo && this.ifNextTo) this.nextShow = true
        if (this.backTo && this.ifBackTo) this.backShow = true
    }
}

</script>
<style lang="scss" scoped>
.button-navigation {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.button-navigation-item {
    min-width: 120px;
    background-color: #0FA1DB;
    border-color: #0FA1DB;
    color: white;
    &:hover {
        background-color: #118bbb;
        color: white;
    }
    .button-navigation-item-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .button-navigation-item-content-title {
            padding: 0 7px;
        }
    }
    &.button-position {
        margin: 0 10px;
    }
}
</style>