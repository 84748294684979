//Импорт компонентов.
import Vue from 'vue'
import App from './App.vue'
import router from './routes/routes'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faArrowLeft,
  faArrowRight,
  faPhone,
  faCalendarAlt,
  faVenusMars,
  faUser,
  faAddressCard,
  faFlag,
  faFile,
  faUserTie,
  faComment,
  faFileImage,
  faFolderOpen,
  faCheck,
  faBomb,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faTrashAlt,
  faStore,
  faFileDownload
 } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//Импорт плагинов
import { VueSpinners } from '@saeris/vue-spinners'
import VueIziToast from 'vue-izitoast';
import Vuelidate from 'vuelidate'
import VueCarousel from 'vue-carousel';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueNaumen from '../src/plugins/naumenChat'
import MaskPlugin from 'vue-jquery-mask'


Vue.use(VueCarousel)
Vue.use(VueSpinners)
Vue.use(VueIziToast)
Vue.use(Vuelidate)
Vue.use(VueNaumen)
Vue.use(MaskPlugin)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })


//Импорт статических файлов.
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss'
import 'izitoast/dist/css/iziToast.min.css';
import "vue2-animate/dist/vue2-animate.min.css";


library.add(
    faUserSecret,
    faArrowLeft,
    faArrowRight,
    faPhone,
    faCalendarAlt,
    faVenusMars,
    faUser,
    faAddressCard,
    faFlag,
    faFile,
    faUserTie,
    faComment,
    faFileImage,
    faFolderOpen,
    faCheck,
    faBomb,
    faPlus,
    faMinus,
    faChevronDown,
    faChevronUp,
    faTrashAlt,
    faStore,
    faFileDownload)

Vue.component('font-awesome-icon', FontAwesomeIcon)



Vue.config.productionTip = false

//Импорт хелперов.
import {Helper} from './helpers/Helper'
import {Enums} from './helpers/Enums'
import { MiddlewareInterceptors } from './helpers/MiddlewareInterceptors';
import moment from 'moment';
import _ from 'lodash';

Vue.prototype.$helper = Helper
Vue.prototype.$enums = Enums
Vue.prototype.$moment = moment
Vue.prototype.$_ = _


new Vue({
  router,
  store,
  created() {
    MiddlewareInterceptors(store)
  },
  render: h => h(App),
}).$mount('#app')
