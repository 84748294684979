//Репозиторий
import { repositoryFactory } from '@/repository/RepositoryFactory.js'

//Сервисы.
const AccountDocumentSetService = repositoryFactory.get('accountDocumentSet')

//Хелперы.
import { Enums } from '../../helpers/Enums'

//Хранилище настроек документов.
export default {
    state: {
        accountDocumentSets: [],
        documents: []
    },
    mutations: {
        setAccountDocumentSets(state, sets) {
            state.accountDocumentSets = sets
        },
        setDocuments(state, documents) {
            state.documents = documents
        }
    },
    actions: {
        //Подгрузить настройки документов.
        loadAccountDocumentSets({commit}) {
            return new Promise((resolve, reject) => {
                AccountDocumentSetService
                    .getAccountDocumentSets(Enums.includes.DocumentType)
                    .then(response => {
                        if (!response.data || response.data.length <= 0)
                            throw new Helper.UserException("Не удалось подгрузить настройки документов.")
                        commit('setAccountDocumentSets', response.data)
                        resolve(response);
                    }).catch(error => { reject(error) })
            })
        },
        setDocuments({commit, getters}, documents) {
            let allDocuments = getters.getAllDocuments
            let res = documents
            allDocuments.forEach(ad => {
                if (!documents.some(d => d.accountDocumentSetId == ad.accountDocumentSetId)) res.push(ad)
            })
            commit('setDocuments', res)
        },
        setAnnotationToCurrentDocuments({commit, getters}, annotations) {
            let documents = getters.getCurrentDocuments
            documents.forEach((val, i, arr) => {
                let ans = annotations.filter(a => a.noteText == val.name);
                if (ans && ans.length > 0) documents[i].annotations = documents[i].annotations.concat(ans)
            })
            commit('setDocuments', documents)
        }
    },
    //Геттеры хранилища настроек.
    getters: {
        getAccountDocumentSets: state => state.accountDocumentSets,

        //Получить из хранилища отфильтрованные по обязательности документы.
        getRequiredAccountDocumentSets: (state, getters) => {
            let account = getters.getAccount;
            var documentRules = state.accountDocumentSets.filter(ads => {
                return (!ads.accountTypeId || ads.accountTypeId == account.accountTypeId) &&
                (!ads.legalEntityType || account.legalEntityType == ads.legalEntityType) &&
                (!ads.isLegalEntityContracted || account.isLegalEntityContracted == ads.isLegalEntityContracted) &&
                (!ads.isLegalEntityOwner || account.isLegalEntityOwner == ads.isLegalEntityOwner) &&
                (!ads.hasRegistration || account.hasRegistration == ads.hasRegistration) &&
                (!ads.isRussiaResident || account.isRussiaResident == ads.isRussiaResident) &&
                (!ads.onlyFreeTasks || account.onlyFreeTasks == ads.onlyFreeTasks) &&
                (!ads.electronicAgreementOptions || account.electronicAgreementOptions == ads.electronicAgreementOptions);
            })
            let requiredSets = documentRules.filter(d => {
                return d.docSetCriteria == Enums.accountDocumentSet.DOC_SEC_CRITERIA.Required &&
                    d.isOriginalRequired == Enums.accountDocumentSet.IS_ORIGINAL_REQUIRED.Yes &&
                    d.electronicAgreement != Enums.accountDocumentSet.IS_ELECTRONIC_AGREEMENT.No
            })
            return requiredSets

        },
        getAllDocuments : state => state.documents,
        getCurrentDocuments : (state, getters) => {
            let allDocuments = state.documents
            let requiredAccountDocumentSet = [ ... getters.getRequiredAccountDocumentSets ]
            let res = allDocuments.filter(d => requiredAccountDocumentSet.some(r => r.accountDocumentSetId == d.accountDocumentSetId))
            requiredAccountDocumentSet.forEach(s => {
                if (!res.some(re => re.accountDocumentSetId == s.accountDocumentSetId)) {
                    res.push({
                        accountDocumentSetId: s.accountDocumentSetId,
                        name: s.documentType ? s.documentType.name : s.name,
                        documentTypeComment: s.documentType ? s.documentType.comment : '',
                        annotations: []
                    })
                }
            })
            return res
        }
    }
}